import React from 'react';

export default props => (

    // <div className="checkbox">

        <label className={`checkbox-inline `} htmlFor={props.name}>
            <input {...props.input}
                readOnly={props.readOnly}
                type={props.type}
                id={props.id}
                className={props.className}
                disabled={props.disabled}
                // key={props.key}
                // value={props.value}
                // checked={props.checked}
                // onChange={props.onChange}
            />

            {props.label}

        </label>
    // </div>

)