import React from 'react'
import Navbar from './navbar'
import logo from '../../image/logo.png';
import icon from '../../image/icone.png';

export default props => (
    <header className='main-header'>
        <a href='/#/' className='logo'>
            <span className='logo-mini'>
            <img src={icon} height="32" alt="" />
            </span>
            <span className='logo-lg'>
                <img src={logo} height="32" alt="" />
            </span>        
        </a>
        <nav className='navbar navbar-static-top'>
            <a href='/#/' className='sidebar-toggle' data-toggle='offcanvas'></a>
            <Navbar />
        </nav>
    </header>
)