import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { init } from './planoActions';
import ListIntervalo from '../intervalo/intervaloList';
// import Button from '../common/form/button';
// import ReactToPrint from 'react-to-print';

class ControladorFase extends Component {

    render() {

        const { controladorID, planoID, anel } = this.props;

        if (!controladorID || controladorID.length === 0) {
            return <div>Carregando...</div>;
        }
        return (
            <div>
                {/* <ReactToPrint
                        trigger={() => <button onClick={() => { console.log('this.componentRef', this.componentRef); }}>Print this out!</button>}
                        content={() => this.componentRef}
                    /> */}

                <ListIntervalo data-controlador={controladorID} data-plano={planoID} data-anel={anel} ref={el => (this.componentRef = el)}/>

                {/* <div className='box-footer'>
                    <Button type='button' className="primary" icon='check' label='Salvar' />
                    <Button type='button' className='default' icon='reply' label='Cancelar' onClick={this.props.init} />
                </div> */}
            </div>
        )
    }
}

const selector = formValueSelector('planoIntervalo');
const mapStateToProps = state => ({
    controladorID: selector(state, 'controlador_id'),
    planoID: selector(state, 'id'),
    anel: selector(state, 'anel'),
})

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ControladorFase);