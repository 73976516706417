import axiosInstance from '../main/axios';

export function getList(id) {    
    const request = axiosInstance.get(`/grupos/permissoes/${id}`);    
    return {
        type: 'PERMISSAO_FETCHED',
        payload: request
    }
}

export function update(values) {    
    const id = values.id ? values.id : ''

    const request = axiosInstance.put(`/grupos/permissoes/${id}`, values);
    
    return {
        type: '',
        payload: request
    }

}
