import React, { Component } from 'react';

import SelectHeaderControlador from '../selectdb/selectHeaderControlador';
import SelectHeaderGrupoFisico from '../selectdb/selectHeaderGrupoFisico';


class ModalSeleciona extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            body: null,            
            grupoFisicoId: this.props.grupoVal,
            contValue: this.props.controladorVal
        }

        this.clickOk = this.clickOk.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    clickOk() {
        this.setState({ show: false });
    }

    render() {        
        
        return (
            <div>
                <SelectHeaderGrupoFisico
                    id='seleciona_grupo_fisico'
                    name='seleciona_grupo_fisico'
                    label='Grupo Físico'
                    cols='12'
                    onChange={(event)=>{                        
                        this.setState({grupoFisicoId: event.target.value});
                        this.props.onChangeGrupoFisico(event);
                    }}
                    value={this.props.grupoVal}// tem que usar value, e tem que usar state pq ele vai ter o valor alterado no onChange
                />

                <SelectHeaderControlador
                    id='seleciona_controlador'
                    name='seleciona_controlador'
                    label='Controlador'
                    cols='12'                   
                    onChange={this.props.onChangeControlador}
                    value={this.props.controladorVal}                    
                    data-grupofisico-id={this.state.grupoFisicoId}
                />
                

            </div>
        )
    }
}

export default ModalSeleciona;