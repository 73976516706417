import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../form/button';

export default props => (
    <Modal show={props.show} onHide={props.handleClose} dialogClassName='modal-seleciona'>
        <Modal.Header className='modal-header' closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
            {props.children}
        </Modal.Body>
        <Modal.Footer className='modal-header'>
            <Button type='button' className='primary' icon='check' label='OK' onClick={props.clickOk} />
            <Button type='button' className='default' icon='close' label='Cancelar' onClick={props.handleClose} />
        </Modal.Footer>
    </Modal>
)
