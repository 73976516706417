import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getPlanoList } from './selectdbAtions';
import LabelAndSelect from '../form/labelAndSelect';

class SelectPlanoSelect extends Component {

    componentWillMount() {
        this.props.getPlanoList(this.props['data-controlador-id']);
    }

    renderOptions() {
        const list = this.props.list || [];
        var listPlano = [];

        list.forEach(plano => {
            if (this.props['data-controlador-id'] == plano.controlador_id) {
                listPlano.push(plano);
            }
        });

        return listPlano.map(plano => (
            <option key={plano.id} value={plano.id}>{plano.descricao}</option>
        ))
    }

    render() {
        
        return (
            <LabelAndSelect 
                label={this.props.label} 
                cols={this.props.cols} 
                id={this.props.id}
                disabled={this.props.disabled}
            >
                <option value='0'>Nenhum</option>
                {this.renderOptions()}
            </LabelAndSelect>
        );
    }
}

const mapStateToProps = state => ({ list: state.planoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getPlanoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlanoSelect)