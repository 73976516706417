import axiosInstance from '../main/axios';
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import { PAGE_SIZE } from '../common/utils/pageSize';

const INITIAL_VALUES = {}

export function getList(page, sorted, filtered) {
    var contId = configLocal.getConfig('cont');
    const request = axiosInstance.get(`/controladorescontagemvolumetrica`, { 
        params: {
            page,
            perPage: PAGE_SIZE,
            contId,
            sort: sorted,
            filter: filtered
        }
    });

    return {
        type: 'CONTROLADOR_CONTAGEM_VOLUMETRICA_FETCHED',
        payload: request
    }
}

export function getDescriptions() {
    var contId = configLocal.getConfig('cont');
    const request = axiosInstance.get(`/descricaocontagemvolumetrica/${contId}`);

    return {
        type: 'DESCRICAO_CONTAGEM_VOLUMETRICA_FETCHED',
        payload: request
    }
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');
    return [
        showTabs('tabList'),
        selectTab('tabList')
    ]
}