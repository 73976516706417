import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group form-group-sm'>
            <label className='label-gt' htmlFor={props.name}>{props.label}</label>
            <select {...props.input}
                className={`form-control`}
                placeholder={props.placeholder} 
                readOnly={props.readOnly}
                id={props.id}
                //onChange={props.onChange}
                defaultValue={props.defaultValue}
                // value={props.value}
                disabled={props.disabled}
            >
                {props.children}
            </select>
        </div>
    </Grid>
)