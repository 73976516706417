import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../form/labelAndSelectForm';

import { getPlanoList } from './selectdbAtions';

class SelectPlano extends Component {

    constructor(props) {
        super(props);

        this.props.getPlanoList();
    } 

    renderOptions() {
        const list = this.props.list || [];
        let listPlano = [];
        let optionPlano = [];
        
        list.forEach(plano => {
            // if (parseInt(this.props['data-controlador-id']) === parseInt(plano.controlador_id)) {
                listPlano.push(plano);
            // }
        });

        optionPlano = listPlano.map(plano => (
            <option key={plano.id} value={plano.id}>{plano.descricao}</option>
        ))

        if (this.props['data-plano-piscante']){
            optionPlano.push(<option key={63} value={63}>{'Plano Intermitente'}</option>)
            optionPlano.push(<option key={32} value={32}>{'Plano Apagado'}</option>)
        }

        return optionPlano;

        // data-plano-piscante
    }

    render() {
        return (
            <Field 
                name='plano_id' 
                component={LabelAndSelectForm} 
                label='Plano' 
                cols={this.props.cols} 
                validate={this.props.validate}
                onChange={this.props.onChange}
            >
                <option value='0'>Selecione um Plano</option>
                {this.renderOptions()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ list: state.planoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getPlanoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlano)

