import React, { Component } from 'react';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import consts from '../consts';

class SelectFaseControlador extends Component {

    constructor(props) {
        super(props);
        this.state = { value: '1 Fase' };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        //this.setState({ value: event.target.value });
    }

    renderRows() {
        let list = [];
        let faseIni = this.props.faseIni || 1; 
        
        for (var i = faseIni; i <= consts.NUM_FASE; i++) {
            list[i] = {id:i, desc:`${i} Fase` };
        }
             
        return list.map(idx => (
            <option key={idx.id} value={idx.id}>{idx.desc}</option>
        ))
    }

    // renderRows() {
    //     const list = [
    //     //    { id: 1, desc: '1 Fase' },
    //         { id: 2, desc: '2 Fase' },
    //         { id: 3, desc: '3 Fase' },
    //         { id: 4, desc: '4 Fase' },
    //         { id: 5, desc: '5 Fase' },
    //         { id: 6, desc: '6 Fase' },
    //         { id: 7, desc: '7 Fase' },
    //         { id: 8, desc: '8 Fase' }
    //     ];

    //     return list.map(idx => (
    //         <option key={idx.id} value={idx.id}>{idx.desc}</option>
    //     ))
    // }

    render() {

        return (
            // <select onChange={this.handleChange}>
            //     <option value="grapefruit">Grapefruit</option>
            //     <option value="lime">Lime</option>
            //     <option value="coconut">Coconut</option>
            //     <option value="mango">Mango</option>
            // </select>


            <Field 
                name={this.props.name}
                component={LabelAndSelectForm} 
                label={this.props.label} 
                value={this.state.value} 
                disabled={this.props.disabled} 
                onChange={this.props.onChange}

                // props={{
                //     onChange: this.props.onChange
                // }}
            >
                {this.renderRows()}
            </Field>
        )
    }
}

export default SelectFaseControlador;