import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showUpdate, remove } from './tipoAlarmeActions';
import SPButton from '../common/form/spButton';
import swal from 'sweetalert2';
import ReactTableGT2A from '../common/grid/gridReactTable';

class TipoAlarmeList extends Component {


    removerTipoAlarme(data) {
        swal({
            title: 'Deseja realmente excluir?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.props.remove(data);
            }
        });   
    }

    render() {

        const tipoAlarme = this.props.list || [];

        const columns = [
            {
                Header: 'Ações',               
                Cell: row => (
                    <div>
                        <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.props.showUpdate(row.original)} />
                        <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerTipoAlarme(row.original)} />
                    </div>
                ),
                className: 'column_icon',
                width: 90
            },
            {
                Header: 'Código',
                accessor: 'id', // String-based value accessors!
                width: 60,
                filterable: true,
                className: 'column_grid column_center',
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Descrição',
                accessor: 'descricao',
                className: 'column_grid',
                filterable: true
            }
        ];

        return (

            <ReactTableGT2A
                data={tipoAlarme}
                columns={columns}
                className='-striped -highlight'
                defaultPageSize={10}                
            />
        )
    }
}
// busca o tipoAlarmeCad la do reducers.js
const mapStateToProps = state => ({ list: state.tipoAlarmeCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ showUpdate, remove }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(TipoAlarmeList)
