const INITIAL_STATE = {summary: {credit: 0, debt: 0}}

// export default function(state = INITIAL_STATE, action) {
//     switch (action.type) {
//         case 'BILLING_SUMMARY_FETCHED':
//             return { ...state, summary: action.payload.data }
//         default:
//             return state
//     }
// }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'DASHBOARD_TOTAL_FETCHED':
            return { ...state, summary: action.payload.data }
        default:
            return state
    }
}