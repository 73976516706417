import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { init } from './dataEspecialActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';

import SelectPlano from '../common/selectdb/selectPlano';
import SelectMes from './selectMes';
import SelectDia from './selectDia';
import html2canvas from 'html2canvas';

class DataEspecialForm extends Component {

    constructor(props) {
        super(props);

        this.componentRef = React.createRef();

        this.salvarDataEspecial = this.salvarDataEspecial.bind(this);
    }

    async salvarDataEspecial() {

        await html2canvas(this.componentRef.current)
            .then((canvas) => {
                canvas.style.display = 'none';
                let image = canvas.toDataURL("png");
                this.props.change('imageLog', image);
            });

        this.props.handleSubmit();
    }

    render() {
        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit, controlador_id, data_mes } = this.props;

        return (
            <form onSubmit={handleSubmit} ref={this.componentRef}>
                <div className='box-body'>

                    {/* <Field name='controlador_id' component={LabelAndInput}
                        label='Controlador' cols='12' readOnly={true} /> */}

                    <Field name='sequencia' component={LabelAndInput}
                        label='Código' cols='12' placeholder='Automático' readOnly={true} />

                    <SelectPlano cols='12' data-controlador-id={controlador_id} data-plano-piscante={true} />

                    <SelectMes cols='12' />    

                    <SelectDia cols='12' data-mes={data_mes || 12} />            

                    <Field name='horario_inicial' component={LabelAndInput} type='time'
                        label='Horário de Inicio' cols='12' placeholder='Informe o horário inicial' />
                    
                    <Field name='horario_final' component={LabelAndInput} type='time'
                        label='Horário de Fim' cols='12' placeholder='Informe o horário final' />

                </div>

                <div className='box-footer'>
                    {/* <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} /> */}
                    <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.salvarDataEspecial} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um 
// form limpo para o update
DataEspecialForm = reduxForm({ form: 'dataEspecialForm', destroyOnUnmount: false })(DataEspecialForm);

const selector = formValueSelector('dataEspecialForm');
const mapStateToProps = state => ({
    controlador_id: selector(state, 'controlador_id') || 0,
    data_mes: selector(state, 'mes') || 0
})

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DataEspecialForm);