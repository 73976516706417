import React, { Component } from 'react';
import DataLocal from '../configuracao/dataLocal';
import UserLocal from '../configuracao/userLocal';

const crypto = require('crypto');

class PermissaoUsuario extends Component {

    constructor(props) {
        super(props);

        this.validaPermissao = this.validaPermissao.bind(this);

        // this.state = {
        //     value: this.props.value
        // }

    }

    validaPermissao() {
        let permissao = false;
        if (this.props.tela) {
            let usuario = UserLocal.getUserConfig('email');
            let data = DataLocal.getData();
            let campo_t = crypto.createHash('sha1').update(this.props.tela).digest('hex');
            let valor_t0 = crypto.createHash('sha1').update(`${0}${usuario}${this.props.tela}`).digest('hex');
            // let valor_t1 = crypto.createHash('sha1').update(`${1}${usuario}${this.props.tela}`).digest('hex');

            if (this.props.campo) {
                // if (valor_t0 === data[campo_t]) {// se tiver permissão para alterar, verifica as permissões dos campos
                //     permissao = true;
                // }
                // permissao = false;
                let campo_c = crypto.createHash('sha1').update(`${this.props.tela}_${this.props.campo}`).digest('hex');
                let valor_c = crypto.createHash('sha1').update(`${1}${usuario}${this.props.tela}_${this.props.campo}`).digest('hex');

                if (valor_c === data[campo_c]) {
                    permissao = true;
                }

            } else {
                if (valor_t0 === data[campo_t]) {// se tiver permissão para alterar, verifica as permissões dos campos
                    permissao = true;
                }
            }
                        
        }


        //if (this.props.condicao)

        // console.log('this.props.tela',this.props.tela);
        // console.log('this.props.campo',this.props.campo);
        // console.log('this.props.children', this.props.children);
        // console.log('this.props.children', this.props.children.props.label);
        return permissao;
    }

    render() {

        if (this.validaPermissao()) {
            if (this.props.condicao) {
                return this.props.children;
            } else {
                return (<span></span>)
            }
        } else {
            if (this.props.condicao) {// se for true não retorna o objeto
                return (<span></span>)
            } else {// se condicao for false retorna o objeto
                return this.props.children;
            }
        }
    }
}

export default PermissaoUsuario;// connect(null, mapDispatchToProps)(LabelAndSelectChange)