import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, getList, getDescriptions } from './contagemVolumetricaActions'

import List from './contagemVolumetricaList';
import SelectConfControlador from '../common/selectdb/selectConfControlador'
import SelectConfGrupoFisico from '../common/selectdb/selectConfGrupoFisico'

class ControladorContagemVolumetrica extends Component {

    componentWillMount() {
        this.changeControlador = this.changeControlador.bind(this);
        this.props.init();
    }

    changeControlador() {
        this.props.getList();
        this.props.getDescriptions();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Contagem Volumétrica' small='Consulta' content={<span><SelectConfControlador onChange={this.changeControlador} anel={false} /> <SelectConfGrupoFisico onChange={this.changeControlador} /></span>} />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, getList, getDescriptions }, dispatch)
export default connect(null, mapDispatchToProps)(ControladorContagemVolumetrica)