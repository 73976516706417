import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import configLocal from '../configuracao/configLocal';

// inicia com valor para o <select> não ficarem vazio quando salvar sem alterar o <select> no insert
const INITIAL_VALUES = {
    dia: 1,
    mes: 1
}

export function getList() {
    var contId = configLocal.getConfig('cont');
    const request = axiosInstance.get(`/datasespeciais/${contId}`)

    return {
        type: 'DATAESPECIAL_FETCHED',
        payload: request
    }
}

export function getControladorList() {
    const contId = configLocal.getConfig('cont');
    const numCont = configLocal.getConfig('numCont');
    const request = axiosInstance.get(`/datasespeciaisremote/${contId}/${numCont}`);

    return {
        type: 'DATAESPECIAL_FETCHED',
        payload: request
    }
}

export function updateDataEspecialControlador(values) {
    const contId = configLocal.getConfig('cont');
    const request = axiosInstance.put(`/datasespeciaisremote/${contId}`, values);

    return {
        type: '',
        payload: request
    }
}

export function removeDataEspecialControlador() {
    var contId = configLocal.getConfig('cont');
    const request = axiosInstance.delete(`/datasespeciais/controlador/deleteall/${contId}`);

    return {
        type: '',
        payload: request
    }
}

export function createUpdateDataEspecialControlador(values) {
    var contId = values.controlador_id;

    const request = axiosInstance.post(`/datasespeciais/controlador/${contId}`, values)
        .then(resp => {
            toastr.success('Sucesso', 'Operação Realizada com sucesso.')
            //dispatch(init())
        })
        .catch(e => {
            e.response.data.errors.forEach(error => toastr.error('Erro', error))
        });

    return {
        type: '',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');

    // if (confirm('Deseja realmente excluir?')) {
    //     return submit(values, 'delete');
    // } else {
    //     return init();
    // }
}

function submit(values, method) {

    return dispatch => {
        const id = values.id ? values.id : '';
        const contId = values.controlador_id;

        axiosInstance[method](`/datasespeciais/${contId}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showUpdate(dataEspecial) {
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('dataEspecialForm', dataEspecial)
    ]
}

export function showDelete(dataEspecial) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('dataEspecialForm', dataEspecial)
    ]
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');

    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('dataEspecialForm', INITIAL_VALUES)
    ]
}
