import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, create, update } from './usuarioActions'
import PermissaoUsuario from '../auth/permissao';

import List from './usuarioList';
import Form from './usuarioForm';
import FormNotificacao from './notificacoesUsuarioForm';

class Usuario extends Component {

    componentWillMount() {
        this.props.init();
    }

    render() {
        const usuList = this.props.list || [];

        if (usuList.length) {
            return (
                <div>
                    <ContentHeader title='Usuários' small='Cadastro' />
                    <Content>
                        <Tabs>
                            <TabsHeader>
                                <TabHeader label='Listar' icon='bars' target='tabList' />
                                <PermissaoUsuario tela="CadUsuario" condicao={true}>
                                    <TabHeader label='Incluir' icon='plus' target='tabCreate' />
                                    <TabHeader label='Alterar' icon='pencil-alt' target='tabUpdate' />
                                    <TabHeader label='Editar Notificações' icon='bell' target='tabNotificacao' />
                                </PermissaoUsuario>
                            </TabsHeader>
                            <TabsContent>
                                <TabContent id='tabList'>
                                    <List />
                                </TabContent>
                                <TabContent id='tabCreate'>
                                    <Form onSubmit={this.props.create} submitClass='primary' submitLabel='Salvar' />
                                </TabContent>
                                <TabContent id='tabUpdate'>
                                    <Form onSubmit={this.props.update} submitClass='primary' submitLabel='Salvar' />
                                </TabContent>
                                <TabContent id='tabNotificacao'>
                                    <FormNotificacao readOnly={true} submitClass='primary' submitLabel='Salvar' />
                                </TabContent>
                            </TabsContent>
                        </Tabs>
                    </Content>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

const mapStateToProps = state => ({ list: state.usuarioCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ init, create, update }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Usuario)