import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, create, update, getList } from './planoActions'

import List from './planoList';
import Form from './planoForm'
import ListPlanoIntervalo from './planoIntervalo';
import PermissaoUsuario from '../auth/permissao';
import SelectConfGrupoFisico from '../common/selectdb/selectConfGrupoFisico'
import SelectConfControlador from '../common/selectdb/selectConfControlador'

class Plano extends Component {

    componentWillMount() {
        this.changeControlador = this.changeControlador.bind(this);
        this.props.init();
    }

    changeControlador() {
        this.props.init();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Planos' small='Cadastro' content={<span><SelectConfControlador anel={false} onChange={this.changeControlador} /> <SelectConfGrupoFisico onChange={this.changeControlador} /></span>} />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                            <PermissaoUsuario tela="CadPlano" condicao={true}>
                                <TabHeader label='Incluir' icon='plus' target='tabCreate' />
                                <TabHeader label='Alterar' icon='pencil-alt' target='tabUpdate' />
                            </PermissaoUsuario>

                            <TabHeader label='Configurar' icon='cog' target='tabIntervalo' />
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List />
                            </TabContent>
                            <TabContent id='tabCreate'>
                                <Form onSubmit={this.props.create} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                            <TabContent id='tabUpdate'>
                                <Form onSubmit={this.props.update} readOnly={true} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                            <TabContent id='tabIntervalo'>
                                <ListPlanoIntervalo />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, create, update, getList }, dispatch)
export default connect(null, mapDispatchToProps)(Plano)