import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapaGoogle from '../mapa/mapaGoogle';
import { Marker } from "react-google-maps";

import ImgClotrollerBlue from '../image/controller-blue.png';
import ImgClotrollerGrey from '../image/controller-grey.png';
// import { GoogleMap } from "react-google-maps";
// import { getLocalCEP } from './controladorActions';

class MapaControlador extends Component {

    constructor(props) {
        super(props);

        this.handleMarkerMouseUp = this.handleMarkerMouseUp.bind(this);
        this.renderMarker = this.renderMarker.bind(this);
        this.testeRotacionaimg = this.testeRotacionaimg.bind(this);

    }

    handleMarkerMouseUp(marker) {

        window.$('#controador_latitude').val(marker.latLng.lat());
        window.$('#controador_longitude').val(marker.latLng.lng());

    }

    renderMarker(handleMarkerMouseUp) {
        const list = this.props.list || [];

        let markers = [];
        let markerAtual = null;
        let markerKey = 0;

        if (list.length) {

            for (var key in list) {

                if (list[key].id == this.props['data-contId']) {
                    
                    markerAtual = (
                        <Marker
                            key={list[key].id}
                            position={{ lat: this.props['data-latitude'], lng: this.props['data-longitude'] }}
                            draggable={true}
                            icon={ImgClotrollerBlue}
                            // icon={{
                            //     url: "http://www.geocodezip.com/mapIcons/SO_20170925_multiplePaths_mod.svg"
                            // }}

                            onMouseUp={this.handleMarkerMouseUp}
                        />
                    );
                } else {
                    markers.push(
                        <Marker
                            key={list[key].id}
                            position={{ lat: list[key].latitude, lng: list[key].longitude }}
                            draggable={false}
                            icon={ImgClotrollerGrey}

                        //onMouseUp={this.handleMarkerMouseUp}
                        />
                    );
                }

                markerKey = list[key].id;

            }
        }

        // se for regitro novo tem que criar usando o centro do cep
        if (this.props['data-contId'] == null) {
            window.$('#controador_latitude').val(this.props.latitude);
            window.$('#controador_longitude').val(this.props.longitude);
            markers.push(
                <Marker
                    key={markerKey + 1}
                    position={{ lat: this.props.latitude, lng: this.props.longitude }}
                    draggable={true}
                    icon={ImgClotrollerBlue}

                    onMouseUp={this.handleMarkerMouseUp}
                />
            );
        }

        if (markerAtual != null) {
            markers.push(markerAtual);
        }

        return markers;
    }

    testeRotacionaimg(map) {

        var rotationAngle = 10;

        setInterval(function () {
            window.$('img[src="http://www.geocodezip.com/mapIcons/SO_20170925_multiplePaths_mod.svg"]').css({
                'transform': 'rotate(' + rotationAngle + 'deg)',
                'transform-origin': '39px 60px'
            });
            rotationAngle += 10;
            rotationAngle %= 360;
        }, 1000);
    }

    render() {
        
        return (
            // <div>
                <MapaGoogle
                    containerElement={<div style={{ height: '80vh', width: '100%' }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    defaultCenter={{ lat: this.props['data-latitude'], lng: this.props['data-longitude'] }}
                >
                    {this.renderMarker()}
                </MapaGoogle>
                /* <input type='button' value='teste' onClick={this.testeRotacionaimg} /> */
            // </div>
        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.list })
export default connect(mapStateToProps, null)(MapaControlador)