import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import Row from '../common/layout/row';
import { Panel, Modal } from 'react-bootstrap';
import Button from '../common/form/button';
import { init } from './erroGeralActions'

import List from './erroGeralList';
import Graph from './erroGeralGraph';

class ControladorErroGeral extends Component {

    constructor(props) {
        super(props);

        this.props.init();
    }   

    render() {
        return (
            <div>
                <ContentHeader title='Controladores com Erro' small='Consulta' />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                            <TabHeader label='Gráfico' icon='chart-pie' target='tabGraph' />
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List />
                            </TabContent>
                            <TabContent id='tabGraph'>
                                <Row>
                                    <Graph />
                                </Row>
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch)
export default connect(null, mapDispatchToProps)(ControladorErroGeral)
