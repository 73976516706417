import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MapaGoogle from '../mapa/mapaGoogle';
import { Marker, InfoWindow } from "react-google-maps";

import ImgFaseGrey from '../image/traffic-grey.png';
import ImgFaseGreen from '../image/traffic-green.png';
import ImgFaseYellow from '../image/traffic-yellow.png';
import ImgFaseRed from '../image/traffic-red.png';
import GifTrafficYellowBlink from '../image/traffic-yellow-blink.gif';

import ImgPedestrianGrey from '../image/pedestrian-grey.png';
import ImgPedestrianGreen from '../image/pedestrian-green.png';
import ImgPedestrianYellow from '../image/pedestrian-yellow.png';
import ImgPedestrianRed from '../image/pedestrian-red.png';
import GifPedestrianRedBlink from '../image/pedestrian-red-blink.gif';

import Content from '../common/template/content'
import configLocal, { getConfig } from '../configuracao/configLocal';
import { downloadCroqui } from '../controlador/controladorActions';
import { Button } from 'react-bootstrap';

import { getControladorFase, getControladorCores, getControladorCoresGF, getControladorCoresGT, getControladorList, getInicioMapa } from './faseActions';
import { getConfControladorGrupoList } from '../common/selectdb/selectdbAtions';
import SelectConfGrupoFisico from '../common/selectdb/selectConfGrupoFisico';
import swal from 'sweetalert2';
import ContentHeader from '../common/template/contentHeader';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
// import { log } from 'util';

class MapaFaseGeral extends Component {

    constructor(props) {
        super(props);

        let grupo = getConfig('grupo');
        this.props.getInicioMapa(grupo).then((response)=>{
            if (parseInt(response.payload.data.latitude) === 1) {
                swal({
                    type: 'warning',
                    title: 'Grupo físico sem controlador cadastrado!'
                })
            }
            this.setState({ inicioMapa: this.props.inicioMapa });
        });
        this.renderMarker = this.renderMarker.bind(this);
        this.handleToggleClose = this.handleToggleClose.bind(this);
        this.renderInfoWindow = this.renderInfoWindow.bind(this);
        this.changeGrupo = this.changeGrupo.bind(this);
        this.changeDisplayTraffic = this.changeDisplayTraffic.bind(this);

        this.state = {
            openInfoWindowMarkerId: '',
            loop: 0,
            intervaloMapaFaseGeral: [],
            listContMap: [],
            loopList: [],
            inicioMapa: {},
            displayTraffic: true
        }
    }

    componentDidMount() {
        let intervaloMapa = setInterval(() => {
            try {
                if (this.state.loop == 0) {
                    this.setState({ loop: 1 });
                    let grupo = getConfig('grupo');
                    this.props.getControladorCoresGF(grupo)
                        .then(() => {
                            this.setState({ loop: 0 });
                        });
                }
            } catch (error) {

            }
        }, 1000);

        this.setState({ intervaloMapaFaseGeral: intervaloMapa });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervaloMapaFaseGeral);
    }

    handleToggleOpen(markerId, controladorId, faseId) {
        this.setState({
            openInfoWindowMarkerId: markerId
        });
    }

    handleToggleClose() {
        this.setState({
            openInfoWindowMarkerId: ''
        });
    }

    renderInfoWindow(data) {
        return (
            <InfoWindow onCloseClick={() => this.handleToggleClose()}>
                <div>
                    <b>Controlador:</b> {data.numero_controlador} <br />
                    <b>Anel:</b> {data.anel} <br />
                    <b>IP: </b> {data.controlador_ip} <br />
                    <b>Porta: </b> {data.controlador_porta} <br />
                    <b>Fase:</b> {data.fase_id} <br />
                    <b>Latitude:</b> {data.fase_latitude} <br />
                    <b>Longitude:</b> {data.fase_longitude} <br />
                    <b>Status:</b> {(data.status_conexao <= 1) ? 'Conectado' : 'Sem Conexão'} <br />
                    {
                        data.croqui_file_id &&
                    <b>Croqui: 
                        <Button onClick={() => downloadCroqui(data.croqui_file_id)} bsSize="xsmall">
                            <i className={'fa fa-download'}></i>
                        </Button>
                    </b>
                    }
                </div>
            </InfoWindow>
        );
    }

    renderMarker() {
        const list = this.props.list || [];

        let markers = [];
        let position = {};

        for (var key in list) {

            let objQuantidadeLatLon = [];
            if ((list[key].quantidade_lat_lon != null) && (list[key].quantidade_lat_lon.length)) {
                objQuantidadeLatLon = JSON.parse(list[key].quantidade_lat_lon);
            }

            if (list[key].fase_latitude != null) {
                position = { lat: parseFloat(list[key].fase_latitude), lng: parseFloat(list[key].fase_longitude) };
            }

            // Se não declarar as variáveis não funciona no onClickMarker
            let controlador_id = list[key].controlador_id;
            let fase_id = list[key].fase_id;

            let icon_cor = parseInt(list[key].tipo) === 1 ? ImgFaseGrey : ImgPedestrianGrey; // Cor padrão = cinza

            // console.log(list[key].numero_controlador, fase_id, list[key].mod_piscante ? 'Intermitente' : list[key].fase_cor);

            if (list[key].mod_piscante) {
                if (fase_id == 1)  {
                    icon_cor = parseInt(list[key].tipo) === 1 ? GifTrafficYellowBlink : GifPedestrianRedBlink;
                } else {
                    continue;
                }
            } else if (list[key].fase_cor === "Vermelho") {
                icon_cor = parseInt(list[key].tipo) === 1 ? ImgFaseRed : ImgPedestrianRed;
            } else if (list[key].fase_cor === "Amarelo") {
                icon_cor = parseInt(list[key].tipo) === 1 ? ImgFaseYellow : ImgPedestrianYellow;
            } else if (list[key].fase_cor === "Verde") {
                icon_cor = parseInt(list[key].tipo) === 1 ? ImgFaseGreen : ImgPedestrianGreen;
            }

            if (objQuantidadeLatLon.length) {
                for (const idx in objQuantidadeLatLon) {
                    let contKey = `${controlador_id}_${fase_id}_${idx}`;
                    position = { lat: parseFloat(objQuantidadeLatLon[idx].latitude), lng: parseFloat(objQuantidadeLatLon[idx].longitude) };
                    markers.push(this.newMarker(contKey, position, icon_cor, controlador_id, fase_id, list[key]));
                }
            } else {
                let contKey = `${controlador_id}_${fase_id}`;
                markers.push(this.newMarker(contKey, position, icon_cor, controlador_id, fase_id, list[key]));
            }
        }

        return markers;
    }

    newMarker(contKey, position, icon_cor, controlador_id, fase_id, data) {
        return <Marker
            key={contKey}
            position={position}
            icon={icon_cor}
            onClick={() => this.handleToggleOpen(contKey, controlador_id, fase_id)}
        >
            {this.state.openInfoWindowMarkerId == contKey && this.renderInfoWindow(data)}
        </Marker>;
    }

    changeGrupo() {
        this.props.getConfControladorGrupoList();
        let grupo = getConfig('grupo');
        this.setState({ inicioMapa: {latitude: null, longitude: null} });
        this.props.getInicioMapa(grupo).then((response)=>{
            if (parseInt(response.payload.data.latitude) === 1) {
                swal({
                    type: 'warning',
                    title: 'Grupo físico sem controlador cadastrado!'
                })
            }
            this.setState({ inicioMapa: this.props.inicioMapa });
        });
    }

    changeDisplayTraffic() {
        const traffic = this.state.displayTraffic;
        this.setState({ displayTraffic: !traffic });
    }

    render() {
        var contLat = null;
        var contLong = null;

        let inicioMapa = this.state.inicioMapa;
        if (!inicioMapa.latitude) {
            return (<div></div>);
        } else {
            contLat = inicioMapa.latitude;
            contLong = inicioMapa.longitude;
        }

        return (
            <div>
                <ContentHeader
                    title='Mapa de Fases'
                    content={
                        <div style={{ 'display': 'flex', 'flex-direction': 'row', 'flex-wrap': 'nowrap', 'justify-content': 'flex-start', 'align-center': 'center' }}>
                            <div style={{ 'fontWeight': 500, 'marginRight': '15px' }}>
                                <label htmlFor="displayTraffic" >
                                    <small>Mostrar tráfego</small>
                                </label>
                                <input
                                    type="checkbox"
                                    id="displayTraffic"
                                    name="displayTraffic"
                                    checked={this.state.displayTraffic}
                                    onChange={this.changeDisplayTraffic}
                                    defaultChecked={true}
                                    style={{
                                        'marginLeft': '5px'
                                    }}
                                />
                            </div>
                            <SelectConfGrupoFisico onChange={this.changeGrupo} emptyText="Todos os Grupos" />
                        </div>
                    }
                />
                <Content>
                    <MapaGoogle
                        containerElement={<div style={{ height: '80vh', width: '100%' }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        defaultCenter={{ lat: contLat, lng: contLong }}
                        displayTraffic={this.state.displayTraffic}
                    >
                        {this.renderMarker()}
                    </MapaGoogle>
                </Content>
            </div>
        )
    }
}

const mapStateToProps = state => ({ list: state.faseCad.listMap, inicioMapa: state.faseCad.inicioMapa })
const mapDispatchToProps = dispatch => bindActionCreators({ getControladorFase, getControladorCores, getControladorCoresGT, getControladorCoresGF, getControladorList, getInicioMapa, getConfControladorGrupoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MapaFaseGeral)