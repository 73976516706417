import axios from 'axios';
import consts from '../consts';
const BASE_URL = consts.OAPI_URL;
const subdomain = window.location.hostname.split('.')[0];

const axiosPublicApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-Subdomain': subdomain,
  },
});

export default axiosPublicApi;
