import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { init } from './adaptativoActions';
import ListIntervalo from '../intervalo/intervaloList';
// import Button from '../common/form/button';
import { log } from 'util';

class AdaptativoIntervalo extends Component {

    render() {

        const { controladorID, planoID } = this.props;
        
        return (
            <div>
                <ListIntervalo data-controlador={controladorID} data-plano={planoID} />

                {/* <div className='box-footer'>
                    <Button type='button' className="primary" icon='check' label='Salvar' />
                    <Button type='button' className='default' icon='reply' label='Cancelar' onClick={this.props.init} />
                </div> */}
            </div>
        )
    }
}

const selector = formValueSelector('adaIntervalo');
const mapStateToProps = state => ({
    controladorID: selector(state, 'controlador_id'),
    planoID: selector(state, 'plano_id')
})

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdaptativoIntervalo);