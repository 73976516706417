const INITIAL_STATE = {list: [], listErros: []}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONTROLADOR_ERRO_GERAL_FETCHED':
            return { 
                ...state,
                list: action.payload.data.list,
                total: action.payload.data.total,
                grupos: action.payload.data.grupos,
                controladores: action.payload.data.controladores,
                ips: action.payload.data.ips,
            }
        case 'CONTROLADOR_ERRO_GERAL_GRAFICO_FETCHED':
            return { ...state, listErros: action.payload.data }
        default:
            return state
    }
}