import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default props => (

    <OverlayTrigger
        overlay={<Tooltip id={`${props.id}_tooltip`}>{props.tooltip}</Tooltip>}
        placement='top'
        delayShow={10}
        delayHide={50}
    >
        <label {...props} className={`font-normal ${props.className}`}>
            {props.text}
        </label>
    </OverlayTrigger>
)

