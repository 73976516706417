import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { init } from './grupoFisicoActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';

class GrupoFisicoForm extends Component {


    render() {
        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit} autocomplete="off">
                <div className='box-body'>
                    <Field name='id' component={LabelAndInput} readOnly={true}
                        label='Código' cols='12' placeholder='Automático' />
                    <Field name='descricao' component={LabelAndInput}
                        label='Descrição' cols='12' placeholder='Informe a descrição' />
                </div>
                <div className='box-footer'>
                    <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um 
// form limpo para o update
GrupoFisicoForm = reduxForm({ form: 'grupoFisicoForm', destroyOnUnmount: false })(GrupoFisicoForm);

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(null, mapDispatchToProps)(GrupoFisicoForm);