import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import axiosInstance from '../main/axios';
import swal from 'sweetalert2';

const { v4: uuidv4 } = require('uuid');

const INITIAL_VALUES = {}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export function getList() {
    const request = axiosInstance.get(`/identificadores_telegram`)

    return {
        type: 'IDENTIFICADOR_TELEGRAM_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submitDelete(values);
}

export function generateToken(values) {
    return newToken(values);
}


function submit(values, method) {
    const id = method == 'put' ? values.id_chat : ''
    return dispatch => {
        axiosInstance[method](`/identificadores_telegram/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init());
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

function submitDelete(values) {
    let data = {data: values };

    return dispatch => {
        const id = values.id ? values.id : ''
        axiosInstance.delete(`/identificadores_telegram/${values.id_chat}`, data)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init());
            })
            .catch(e => {
                let erro = e.response.data.errors;
                erro.forEach(error => toastr.error('Erro', error))
                
            })
    }
}

async function newToken(values) {
    values.uuid = uuidv4();
    var sLinkBot = process.env.LINK_CHAT_TELEGRAM;

    return dispatch => {
        axiosInstance.post(`/generate_token_identificador_telegram`, values)
            .then(resp => {
                swal({
                    width: '520px',
                    height: '300px',
                    title: "<b>Token Gerado!</b>", 
                    html: `
                    <h3>${values.uuid}</h3> 
                    <br>
                    <div style="font-size: 18px; text-align: left;">
                        <p><strong>Passo 1:</strong> Copiar o Token Gerado</p>
                        <p><strong>Passo 2:</strong> Acessar o BOT e adicionar o mesmo em um grupo, ou iniciar uma conversa privada. <a href="${sLinkBot}" target="_blank">Clique aqui para acessar o BOT</a></p>
                        <p><strong>Passo 3:</strong> Colar o Token fornecido na conversa ou grupo desejado.</p>
                        <p><strong>Passo 4:</strong> Por fim, clicar no botão abaixo: "Atualizar Identificadores"</p>
                        <p><strong>Passo 5:</strong> Pronto! Agora você receberá mensagens em seu Telegram!</p>
                    </div>
                `,
                    icon: 'info',
                    confirmButtonText: "Atualizar Identificadores",
                }).then(async (result) => {
                    if (result.value) {
                        try {
                            axiosInstance.post(`/refresh_identificadores_telegram`, {
                                responseType: 'text' // Indicando que o tipo de resposta é texto
                            }).then(response => {
                                if (response.status == 200) {
                                    let iCountIdentifiersRefresh = response.data;
                                    let sMessage = '';
                    
                                    if (iCountIdentifiersRefresh) {
                                        sMessage = 'Um novo identificador foi cadastrado.';
                                
                                        if (iCountIdentifiersRefresh > 1) {
                                            sMessage = 'Foram cadastrados '+ iCountIdentifiersRefresh + ' identificadores novos.';
                                        }
                                    }
                                    toastr.success('Sucesso', sMessage)
                                } else {
                                    toastr.info('Atenção', 'Nenhum identificador foi cadastrado!');
                                }
                            }).catch(error => {
                                console.error('Erro ao atualizar os identificadores:', error);
                            });
                        
                        } catch (error) {
                            console.error('Erro ao atualizar os identificadores:', error);
                        }
                    }
                    await sleep(1000);
                    dispatch(init());
                });
            })
            .catch(e => {
                let erro = e.response.data.errors;
                erro.forEach(error => toastr.error('Erro', error))
                
            })
    }
}

export function showUpdate(identificadorTelegram) {
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('identificadorTelegramForm', identificadorTelegram)
    ]
}

export function showDelete(identificadorTelegram) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('identificadorTelegramForm', identificadorTelegram)
    ]
}

export async function init() {
    return [
        showTabs('tabList', 'tabCreate', 'tabGenerate'),
        selectTab('tabList'),
        getList(),
        initialize('identificadorTelegramForm', INITIAL_VALUES),
    ]
}


