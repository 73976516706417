import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { init } from './identificadorTelegramActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';

class IdentificadorTelegramFormToken extends Component {

    render() {
        const { handleSubmit } = this.props;
 
        return (
            <form onSubmit={handleSubmit} autocomplete="off">
                <div className='box-body'>
                    <Field name='descricao' component={LabelAndInput} maxLength={60}
                        label='Descrição' cols='12' placeholder='Informe uma descrição referente ao grupo ou a conversa' />
                </div>
                <div className='box-footer'>
                    <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}

IdentificadorTelegramFormToken = reduxForm({ form: 'IdentificadorTelegramFormToken', destroyOnUnmount: false })(IdentificadorTelegramFormToken);
const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(null, mapDispatchToProps)(IdentificadorTelegramFormToken);