import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
// import { Router, Route, IndexRoute, Redirect, hashHistory } from 'react-router';

//import App from './app'
// import AuthOrApp from './authOrApp';
import Dashboard from '../dashboard/dashboard';

import CadUsuario from '../usuario/usuario';
import CadControlador from '../controlador/controlador';
import CadGrupo from '../grupo/grupo';
import CadFase from '../fase/fase';
import CadPlano from '../plano/plano';
import CadTrocaPlano from '../troca_plano/trocaPlano';
import CadDataEspecial from '../data_especial/dataEspecial';
import CadIntervaloPlano from '../intervalo/intervalo';
import CadSelecaoAutomatica from '../sap/selecaoAutomatica';
import CadDetector from '../detector/detector';
import CadTipoAlarme from '../tipo_alarme/tipoAlarme';
import CadGrupoFisico from '../grupo_fisico/grupoFisico';
import MapaDeFases from '../fase/mapaFaseGeral';
import ConControladorErro from '../controlador_erro/erro';
import ContagemVolumetrica from '../contagem_volumetrica/contagemVolumetrica';
import CadAdaptativo from '../adaptativo/adaptativo';
import ExecPlanoForcado from '../plano_forcado/planoForcado';
import ConControladorErroGeral from '../controlador_erro_geral/erroGeral';
import ConLogUsuario from '../log_usuario/logUsuario';
import CadIdentificadorTelegram from '../telegram/identificadorTelegram';
// import ListContagem from '../contagem/graficoContagem'; se não é chamado por menu, não tem rota

export default props => (

    <div className='content-wrapper'>
        <Switch>
            <Route exact path='/' component={Dashboard} />

            <Route path='/cadusuario' component={CadUsuario} />
            <Route path='/cadcontrolador' component={CadControlador} />
            <Route path='/cadgrupo' component={CadGrupo} />
            <Route path='/cadfase' component={CadFase} />
            <Route path='/cadplano' component={CadPlano} />
            <Route path='/cadtrocaplano' component={CadTrocaPlano} />
            <Route path='/caddataespecial' component={CadDataEspecial} />
            <Route path='/cadintervaloplano' component={CadIntervaloPlano} />
            <Route path='/cadselecaoautomatica' component={CadSelecaoAutomatica} />
            <Route path='/caddetector' component={CadDetector} />
            <Route path='/cadtipoalarme' component={CadTipoAlarme} />
            <Route path='/cadgrupofisico' component={CadGrupoFisico} />
            <Route path='/mapadefases' component={MapaDeFases} />
            <Route path='/concontroladorerro' component={ConControladorErro} />
            <Route path='/contagemvolumetrica' component={ContagemVolumetrica} />
            <Route path='/cadadaptativo' component={CadAdaptativo} />
            <Route path='/execplanoforcado' component={ExecPlanoForcado} />
            <Route path='/concontroladorerrogeral' component={ConControladorErroGeral} />
            <Route path='/conlogusuario' component={ConLogUsuario} />            

            <Route path='/telegram' component={CadIdentificadorTelegram} />        

            <Redirect from='*' to='/' />
        </Switch>
    </div>
)