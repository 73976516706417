import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'

import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'

import AuthOrApp from './main/authOrApp';
import reducers from './main/reducers'
import { register } from './serviceWorker'

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const subdomain = window.location.hostname.split('.')[0];

const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers, devTools);
store.dispatch({
    type: 'SET_SUBDOMAIN',
    subdomain,
});

ReactDOM.render(
    <Provider store={store}>
        <AuthOrApp />
    </Provider>
, document.getElementById('app'))

// registerServiceWorker()
register({onUpdate: () => {
    window.location.reload(true);
}});


