import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { init } from './controladorActions';
import ListFase from '../fase/faseList';
import Button from '../common/form/button';

class ControladorFase extends Component {

    render() {

        const { controladorID } = this.props;

        return (
            <div>
                <div className='box-header'>
                    <h5 className='box-title'>{`Controlador: ${controladorID}`}</h5>
                </div>
                <ListFase data-id={controladorID} />

                <div className='box-footer'>

                    <Button type='button' className='primary' icon='reply' label='Voltar' onClick={this.props.init} />
                </div>
            </div>
        )
    }
}

const selector = formValueSelector('controladorFaseList');
const mapStateToProps = state => ({
    controladorID: selector(state, 'id')
})

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ControladorFase);