import React from 'react';
import { isMobile } from 'react-device-detect';

const menuItemClick = props => {
    return (
        <li>
            <a
                href='#'
                className={'button_link'}
                data-toggle={(isMobile) ? 'push-menu' : ''}
                onClick={(e) => props.onClick(e, props)}
            >
                <i className={`fa fa-${props.icon}`}></i> <span>{props.label}</span>
            </a>
        </li>
    )
}

export default menuItemClick;