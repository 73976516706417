import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MapaGoogle from '../mapa/mapaGoogle';
import { Marker } from "react-google-maps";
import ImgFaseBlue from '../image/traffic-blue.png';
import ImgFaseGrey from '../image/traffic-grey.png';
import ImgPedestrianBlue from '../image/pedestrian-blue.png';
import ImgPedestrianGrey from '../image/pedestrian-grey.png';

import { update } from './faseActions';

class MapaFase extends Component {

    constructor(props) {
        super(props);

        this.handleMarkerMouseUp = this.handleMarkerMouseUp.bind(this);
        this.handleMarkerPedestreMouseUp = this.handleMarkerPedestreMouseUp.bind(this);
        this.renderMarker = this.renderMarker.bind(this);

        this.state = {
            fases: []
        };

    }

    componentWillMount() {
        this.setState({ fases: this.props['data-fases'] });
    }

    handleMarkerMouseUp(marker) {
        const data = this.state.fases;

        data[this.props['data-idx']].latitude = marker.latLng.lat();
        data[this.props['data-idx']].longitude = marker.latLng.lng();

        this.setState({ data });
    }

    handleMarkerPedestreMouseUp(marker, idx) {
        const data = this.state.fases;

        let pedestreLatLon = JSON.parse(data[this.props['data-idx']].quantidade_lat_lon);
        pedestreLatLon[idx].latitude = marker.latLng.lat();
        pedestreLatLon[idx].longitude = marker.latLng.lng();

        data[this.props['data-idx']].quantidade_lat_lon = JSON.stringify(pedestreLatLon);
        this.setState({ data });
    }

    renderMarker(handleMarkerMouseUp) {
        // const list = this.props.list || [];
        // console.log('list',list1);
        const list = this.state.fases;

        let markers = [];
        let position = { lat: this.props.latitude, lng: this.props.longitude };
        let markerAtual = [];

        for (var key in list) {

            // console.log('list[key]',list[key]);
            let objQuantidadeLatLon = [];
            if ((list[key].quantidade_lat_lon != null) && (list[key].quantidade_lat_lon.length)) {
                objQuantidadeLatLon = JSON.parse(list[key].quantidade_lat_lon);
            }

            if (list[key].latitude != null) {
                position = { lat: list[key].latitude, lng: list[key].longitude };
            }

            if (list[key].id == this.props['data-faseId']) {// se for a fase atual
                if (objQuantidadeLatLon.length) {// se for pedestre e tiver quantidade
                    // console.log('objPedestreLatLon --> ', objQuantidadeLatLon);

                    for (const idx in objQuantidadeLatLon) {
                        markerAtual.push(
                            <Marker
                                key={list[key].controlador_id + '_' + list[key].id + '_' + idx}
                                position={{ lat: parseFloat(objQuantidadeLatLon[idx].latitude), lng: parseFloat(objQuantidadeLatLon[idx].longitude) }}
                                draggable={true}
                                icon={parseInt(list[key].tipo) === 1 ? ImgFaseBlue : ImgPedestrianBlue}
                                onMouseUp={(event) => {
                                    this.handleMarkerPedestreMouseUp(event, idx);
                                }}
                            />
                        );
                    }
                } else { // se for veículo(não tem quantidade)
                    markerAtual.push(
                        <Marker
                            key={list[key].controlador_id + '_' + list[key].id}
                            position={position}
                            draggable={true}
                            icon={parseInt(list[key].tipo) === 1 ? ImgFaseBlue : ImgPedestrianBlue}
                            onMouseUp={this.handleMarkerMouseUp}
                        />
                    );
                }

            } else {
                if (objQuantidadeLatLon.length) {// se for pedestre e tiver quantidade
                    // console.log('objPedestreLatLon --> ', objPedestreLatLon);

                    for (const idx in objQuantidadeLatLon) {
                        markerAtual.push(
                            <Marker
                                key={list[key].controlador_id + '_' + list[key].id + '_' + idx}
                                position={{ lat: parseFloat(objQuantidadeLatLon[idx].latitude), lng: parseFloat(objQuantidadeLatLon[idx].longitude) }}
                                draggable={false}
                                icon={parseInt(list[key].tipo) === 1 ? ImgFaseGrey : ImgPedestrianGrey}
                            />
                        );
                    }
                } else { // se for veículo(não tem quantidade)
                    markers.push(
                        <Marker
                            key={list[key].controlador_id + '_' + list[key].id}
                            position={position}
                            draggable={false}
                            icon={parseInt(list[key].tipo) === 1 ? ImgFaseGrey : ImgPedestrianGrey}
                        />
                    );
                }
            }
        }

        // adiciona o atual por ultimo para ficar em cima
        // if (markerAtual != null) {
        if (markerAtual.length) {
            // console.log('markerAtual', markerAtual);

            markers.push(markerAtual);
        }

        return markers;

    }

    render() {
        return (
            <MapaGoogle
                containerElement={<div style={{ height: '80vh', width: '100%' }} />}
                mapElement={<div style={{ height: `100%` }} />}
                defaultCenter={{ lat: this.props['data-latitude'], lng: this.props['data-longitude'] }}
            >
                {this.renderMarker()}
            </MapaGoogle>
        )
    }
}

const mapStateToProps = state => ({ list: state.faseCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ update }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MapaFase)