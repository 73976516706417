import React, { Component } from 'react';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import consts from '../consts';

class SelectIntervalo extends Component {

    renderRows() {
        var list = [];
        
        for (var i = 4; i <= consts.NUM_INTERVALO; i++) {
            list[i] = {id:i, desc:i};
        }
             
        return list.map(sequencia => (
            <option key={sequencia.id} value={sequencia.id}>{sequencia.desc}</option>
        ))
    }

    render() {
        return (
            <Field 
                name={this.props.name}
                component={LabelAndSelectForm}
                label={this.props.label}
                cols={this.props.cols}
                disabled={this.props.disabled} >
                {this.renderRows()}
            </Field>
        )
    }
}

export default SelectIntervalo;