import React, { Component } from 'react';
// import { connect, getFormMeta, getFormSyncErrors } from 'redux-form';
import Grid from '../layout/grid';

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group form-group-sm'>
            <label className='label-gt' htmlFor={props.name}>{props.label}</label>
            <input {...props.input} className={`form-control ${props.meta.touched && ((props.meta.error && 'form-valid') || (props.meta.warning && 'form-valid'))}`}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                type={props.type}
                id={props.id}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
                step={props.step}
                //value={props.value}
                // value={props.defaultValue}
                defaultValue={props.defaultValue}
                // onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
            // onChange={props.onChange}
            />
            {props.meta.touched && ((props.meta.error && <span className='msg_span'>{props.meta.error}</span>) || (props.meta.warning && <span className='msg_span'>{props.meta.warning}</span>))}

        </div>
    </Grid>
)



// class LabelAndInput extends Component {

//     constructor(props){
//         super(props);

//         this.labelonBlur = this.labelonBlur.bind(this);
//     }

//     labelonBlur(){

//     }

//     render() {

//         // console.log('this.props.meta', this.props.meta);        

//         return (

//             <Grid cols={this.props.cols}>
//                 <div className='form-group form-group-sm'>
//                     <label className='label-gt' htmlFor={this.props.name}>{this.props.label}</label>
//                     <input {...this.props.input} className='form-control'
//                         placeholder={this.props.placeholder}
//                         readOnly={this.props.readOnly}
//                         type={this.props.type}
//                         id={this.props.id}
//                         min={this.props.min}
//                         max={this.props.max}
//                         //value={props.value}
//                         // value={props.defaultValue}
//                         defaultValue={this.props.defaultValue}
//                         onFocus={this.props.onFocus}
//                         // onBlur={this.props.onBlur}
//                         onBlur={(e) => {
//                             if (!e.target.value)
//                                 this.props.input.onBlur(e);
//                             if (this.props.onBlur != null){
//                                 this.props.onBlur(e);
//                             }             
//                             // this.props.input.onBlur(e);
//                            }}
//                         onKeyDown={this.props.onKeyDown}
//                     // onChange={props.onChange}
//                     />
//                     {this.props.meta.touched && ((this.props.meta.error && <span>{this.props.meta.error}</span>) || (this.props.meta.warning && <span>{this.props.meta.warning}</span>))}
//                 </div>
//             </Grid>

//         )
//     }
// }

// export default LabelAndInput;// connect(null, mapDispatchToProps)(LabelAndSelectChange)




// export default props => (
//     <Grid cols={props.cols}>
//         <div className='form-group form-group-sm'>
//             <label className='label-gt' htmlFor={props.name}>{props.label}</label>
//             <input {...props.input} className='form-control'
//                 placeholder={props.placeholder}
//                 readOnly={props.readOnly}
//                 type={props.type}
//                 id={props.id}
//                 name={props.name}
//                 min={props.min}
//                 max={props.max}
//                 value={props.value}
//                 // defaultValue={props.defaultValue}
//                 onBlur={props.onBlur}
//                 onChange={props.onChange}
//             />
//             {props.meta.touched && ((props.meta.error && <span>{props.meta.error}</span>) || (props.meta.warning && <span>{props.meta.warning}</span>))}

//         </div>
//     </Grid>
// )

// class LabelAndInput extends Component {

//     constructor(props) {
//         super(props);

//         // console.log(this.props);


//         this.state = {
//             value: this.props.value
//         }

//     }

//     componentWillMount() {
//         // this.props.getGrupoFisicoList();
//     }

//     render() {

//         return (

//             <Grid cols={this.props.cols}>
//                 <div className='form-group form-group-sm'>
//                     <label className='label-gt' htmlFor={this.props.name}>{this.props.label}</label>
//                     <input {...this.props.input} className='form-control'
//                         placeholder={this.props.placeholder}
//                         readOnly={this.props.readOnly}
//                         type={this.props.type}
//                         id={this.props.id}
//                         min={this.props.min}
//                         max={this.props.max}
//                         // value={props.value}
//                         // defaultValue={this.state.value}
//                         value={this.state.value}
//                         onBlur={this.props.onBlur}
//                         onKeyDown={this.props.onKeyDown}
//                         onChange={(event)=>{      
//                             console.log('event.target: ===> ',event.target);

//                             this.setState({value: event.target.value });                            
//                         }}

//                     />

//                 </div>
//             </Grid>

//         )
//     }
// }

// // const mapDispatchToProps = dispatch => bindActionCreators({ getGrupoFisicoList }, dispatch)
// export default LabelAndInput;// connect(null, mapDispatchToProps)(LabelAndSelectChange)