const INITIAL_STATE = { list: [], listCod: [] }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PLANO_FETCHED':
            return { ...state, list: action.payload.data }
        case 'PLANOCONTROLADOR_FETCHED':
            return { ...state, list: action.payload.data }
        case 'PLANOCODIGO_FETCHED':
            return { ...state, listCod: action.payload.data }
        default:
            return state
    }
}