import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import { init, update, create } from './grupoActions';
import LabelAndInput from '../common/form/labelAndInput';
import PermissaoGrupo from '../permissao/permissaoList';
import { getList } from '../permissao/permissaoActions';
import Button from '../common/form/button';

import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';

class GrupoForm extends Component {

    constructor(props) {
        super(props);

        this.geraGridPermissao = this.geraGridPermissao.bind(this);
        this.salvarGrupo = this.salvarGrupo.bind(this);

        this.state = {
            show: false,
            html: '',
            permissao: []
        };
    }

    componentWillMount() {
        this.geraGridPermissao();
    }

    geraGridPermissao() {
        this.setState({
            html: <div>Carregando...</div>
        });

        // await sleep(100);

        // if (this.props.controladorId != null) {

        this.props.getList(this.props.grupoID)
            .then((result) => {
                this.setState({ permissao: result.payload.data });
                this.setState({
                    html: <PermissaoGrupo
                        // data-grupo={1}
                        data-list={this.state.permissao}
                    />
                });
            })
        // }
    }

    salvarGrupo() {
        this.setState({ show: true });
        this.props.valores['permissao'] = this.state.permissao;

        if (this.props.valores.id != null) {
            this.props.update(this.props.valores)
                .then((result) => {
                    this.setState({ show: false });
                    this.props.init();
                })
                .catch(e => {
                    this.setState({ show: false });
                });
        } else {
            this.props.create(this.props.valores)
                .then((result) => {
                    this.setState({ show: false });
                    this.props.init();
                })
                .catch(e => {
                    this.setState({ show: false });
                });
        }
    }

    render() {
        // os eventos handleSubmit, vem do reduxForm
        //const { sumOfCredits, sumOfDebts } = this.calculateSummary()
        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false}
                    centered="verticallyCenter"
                >

                    <Modal.Body className='modal-body modal-load-body'>

                        <div >

                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>

                        </div>


                    </Modal.Body>

                </Modal>
                <form>
                    <div className='box-body'>
                        <Field name='id' component={LabelAndInput} readOnly={true}
                            label='Código' cols='12' placeholder='Automático' />
                        <Field name='descricao' component={LabelAndInput}
                            label='Descrição' cols='12' placeholder='Informe a descrição' />
                    </div>
                    <div className='box-footer'>
                        {this.state.html}
                        <Button type='button' className={this.props.submitClass} icon='check' label='Salvar' onClick={this.salvarGrupo} />
                        <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                    </div>
                </form>
            </div>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um 
// form limpo para o update
GrupoForm = reduxForm({ form: 'grupoForm', destroyOnUnmount: false })(GrupoForm);

const selector = formValueSelector('grupoForm');
const mapStateToProps = state => ({
    grupoID: selector(state, 'id'),
    valores: getFormValues('grupoForm')(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({ init, getList, update, create }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GrupoForm);