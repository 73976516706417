import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Moment from 'moment';
import {
    showUpdate,
    remove,
    getList,
    getControladorList,
    updateDataEspecialControlador,
    createUpdateDataEspecialControlador,
    removeDataEspecialControlador
} from './dataEspecialActions';
import SPButton from '../common/form/spButton';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';

import ReactTableGT2A from '../common/grid/gridReactTable';
import ButtonTooltip from '../common/form/buttonTooltip';
import If from '../common/operator/if';
import swal from 'sweetalert2';
import PermissaoUsuario from '../auth/permissao';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class DataEspecialList extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);

        this.lerDataEspecialControlador = this.lerDataEspecialControlador.bind(this);
        this.salvarDataEspecialControlador = this.salvarDataEspecialControlador.bind(this);
        this.salvarControladorDB = this.salvarControladorDB.bind(this);
        this.lerDataEspecialSistema = this.lerDataEspecialSistema.bind(this);
        this.removerDatasEspeciais = this.removerDatasEspeciais.bind(this);
        this.alterarDatasEspeciais = this.alterarDatasEspeciais.bind(this);
        this.intervalGetList = 0;

        this.state = {
            salvarDB: false,
            show: false
        };
    }

    componentWillUnmount() {
        clearInterval(this.intervalGetList);
    }

    lerDataEspecialControlador() {
        // const dataEspecial = this.props.list || [];

        // if (dataEspecial.length > 0) {
        this.setState({ show: true });
        this.props.getControladorList()
            .then((result) => {
                this.setState({ show: false });

                if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                    toastr.error('Erro', result.payload.response.data.errors[0]);
                    this.props.getList();
                } else {
                    toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                    this.setState({ salvarDB: true });
                }
            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', e.response.data.errors[0]);
                this.props.getList(this.props['data-controlador'], this.props['data-plano']);
            });
        // }

    }

    lerDataEspecialSistema() {
        this.props.getList();
        this.setState({ salvarDB: false });
    }

    async salvarDataEspecialControlador() {

        let confirma = false;

        await swal.fire({
            title: 'Enviar as configurações de datas especiais para a "Central"?',
            html: '<h5>Essa ação vai substituir as configurações existentes na central.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            confirma = result.value;
        });
        await sleep(1000);

        if (confirma) {

            this.setState({ show: true }); // da show no modal de aguarde

            const data = this.props.list || [];
            this.props.updateDataEspecialControlador(data)
                .then((result) => {
                    this.setState({ show: false });
                    if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                        toastr.error('Erro', result.payload.response.data.errors[0]);
                    } else {
                        toastr.success('Sucesso', 'Datas especiais enviadas com sucesso.');
                        window.$('body').css({'padding-right' : '0px'});
                    }
                })
                .catch(e => {
                    this.setState({ show: false });
                    toastr.error('Erro', e.response.data.errors[0]);
                });
        }
    }

    salvarControladorDB() {
        swal({
            title: 'Substituir os dados atuais do sistema pelos dados do controlador?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.setState({ salvarDB: false });
                const data = this.props.list || [];

                if (data.length > 0) {
                    this.props.removeDataEspecialControlador()
                        .then((result) => {
                            if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                                toastr.error('Erro', result.payload.response.data.errors[0]);
                            } else {
                                for (var key in data) {
                                    this.props.createUpdateDataEspecialControlador(data[key]);
                                }
                            }

                        })
                        .catch(e => {
                            toastr.error('Erro', e);
                        });
                } else {
                    // faz o update na tabela selecao_automatica
                    for (var key in data) {
                        this.props.createUpdateDataEspecialControlador(data[key]);
                    }
                }
            }
        });
    }

    alterarDatasEspeciais(data) {

        if (this.state.salvarDB) {
            swal({
                type: 'warning',
                title: 'Só é possivel alterar depois de gravar os dados do controlador no sistema'
            })
        } else {
            this.props.showUpdate(data);
        }
    }

    removerDatasEspeciais(data) {
        if (this.state.salvarDB) {
            swal({
                type: 'warning',
                title: 'Só é possivel excluir depois de gravar os dados do controlador no sistema'
            })
        } else {
            swal({
                title: 'Deseja realmente excluir?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: 'primary',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: 'false'
            }).then((result) => {
                if (result.value) {

                    this.props.remove(data);

                }
            });
        }
    }

    handleClose() {
        this.setState({ show: false });
    }

    render() {

        const dataEspecial = this.props.list || [];

        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <div>
                        <PermissaoUsuario tela="CadDataEspecial" condicao={true}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.alterarDatasEspeciais(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerDatasEspeciais(row.original)} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadDataEspecial" condicao={false}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' disabled='disabled' />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' disabled='disabled' />
                        </PermissaoUsuario>
                    </div>
                ),
                width: 90,
                filterable: true,
                className: 'column_icon',
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Plano',
                accessor: 'plano_id', // String-based value accessors!
                width: 50,
                className: 'column_grid column_center',
                filterable: true
            },
            {
                Header: 'Código',
                accessor: 'sequencia', // String-based value accessors!
                width: 50,
                className: 'column_grid column_center',
                filterable: true
            },
            {
                Header: 'Dia',
                accessor: 'dia',
                className: 'column_grid column_center',
                width: 40
            },
            {
                Header: 'Mês',
                accessor: 'mes',
                className: 'column_grid column_center',
                width: 40
            },
            {
                Header: 'Hor. Inicial',
                accessor: 'horario_inicial',
                className: 'column_grid column_center',
                Cell: ({ value }) => (value ? value.substring(0, 5) : '')
            },
            {
                Header: 'Hor. Final',
                accessor: 'horario_final',
                className: 'column_grid column_center',
                Cell: ({ value }) => (value ? value.substring(0, 5) : '')
            }
        ];

        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    // dialogClassName='modal-load'
                    backdrop={false}
                >
                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <ReactTableGT2A
                    data={dataEspecial}
                    columns={columns}
                    className='-striped -highlight'
                    defaultPageSize={15}
                />
                 <div className='box-footer'>
                    <PermissaoUsuario tela="CadDataEspecial" condicao={true}>
                        <ButtonTooltip type='button' id='btnDataLerControlador' className='success' icon='download' label='Ler Controlador' data-title='Busca os dados do controlador' onClick={this.lerDataEspecialControlador} />
                        <ButtonTooltip type='button' id='btnDataEnviarControlador' className='warning' icon='upload' label='Enviar Controlador' data-title='Envia os dados para o controlador' onClick={this.salvarDataEspecialControlador} />
                        <If condicao={this.state.salvarDB}>
                            <ButtonTooltip type='button' id='btnDataSalvarDB' className='primary' icon='check' label='Salvar no sistema' data-title='Salva no sistema os dados que viaram do controlado' onClick={this.salvarControladorDB} />
                        </If>
                        <If condicao={this.state.salvarDB}>
                            <ButtonTooltip type='button' id='btnDataEspLerSistema' className='primary' icon='file-download' label='Ler do sistema' data-title='Busca os dados do banco de dados do sistema' onClick={this.lerDataEspecialSistema} />
                        </If>
                    </PermissaoUsuario>

                    <PermissaoUsuario tela="CadDataEspecial" condicao={false}>
                        <ButtonTooltip type='button' id='btnDataLerControlador' className='success' icon='download' label='Ler Controlador' disabled='disabled' />
                        <ButtonTooltip type='button' id='btnDataEnviarControlador' className='warning' icon='upload' label='Enviar Controlador' disabled='disabled' />
                        <If condicao={this.state.salvarDB}>
                            <ButtonTooltip type='button' id='btnDataSalvarDB' className='primary' icon='check' label='Salvar no sistema' disabled='disabled' />
                        </If>
                        <If condicao={this.state.salvarDB}>
                            <ButtonTooltip type='button' id='btnDataEspLerSistema' className='primary' icon='file-download' label='Ler do sistema' disabled='disabled' />
                        </If>
                    </PermissaoUsuario>
                </div>
            </div>

        )
    }
}
// busca o planoCad la do reducers.js
const mapStateToProps = state => ({ list: state.dataEspecialCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({
    showUpdate,
    remove,
    getList,
    getControladorList,
    updateDataEspecialControlador,
    createUpdateDataEspecialControlador,
    removeDataEspecialControlador
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(DataEspecialList)
