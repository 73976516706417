import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { toastr } from 'react-redux-toastr'

import { init, getList } from './trocaPlanoActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';

import SelectPlano from '../common/selectdb/selectPlano';
import Checkbox from '../common/form/checkbox';
import html2canvas from 'html2canvas';
import { getConfig } from '../configuracao/configLocal';
import Grid from '../common/layout/grid';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';

class TrocaPlanoForm extends Component {

    constructor(props) {
        super(props);

        this.componentRef = React.createRef();

        this.state = {
            checked: [
                props.domingo,
                props.segunda,
                props.terca,
                props.quarta,
                props.quinta,
                props.sexta,
                props.sabado
            ],
            fields: [
                'domingo',
                'segunda',
                'terca',
                'quarta',
                'quinta',
                'sexta',
                'sabado'
            ],
            show: false
        };

        this.diasUteis = this.diasUteis.bind(this);
        this.finalSemana = this.finalSemana.bind(this);
        this.marcarTodos = this.marcarTodos.bind(this);
        this.desMarcarTodos = this.desMarcarTodos.bind(this);
        this.onBlurHorarioInicial = this.onBlurHorarioInicial.bind(this);
        this.salvarTroca = this.salvarTroca.bind(this);
    }

    diasUteis() {
        this.marcarTodos();

        this.props.change('domingo', 0);
        this.props.change('sabado', 0);
    }

    finalSemana() {
        let exibeAlerta = false;

        this.desMarcarTodos();

        if (!this.validaTrocaPlano(0)) {
            this.props.change('domingo', 1);
        } else {
            exibeAlerta = true;
        }

        if (!this.validaTrocaPlano(6)) {
            this.props.change('sabado', 1);
        } else {
            exibeAlerta = true;
        }

        if (exibeAlerta) {
            toastr.warning('Atenção', 'Não foi possível marcar todos os dias escolhidos. Alguns dias já estão configurados para este mesmo horário.');
        }
    }

    marcarTodos() {
        let i = 0;
        let exibeAlerta = false;

        for (var key of this.state.fields) {
            if (!this.validaTrocaPlano(i)) {
                this.props.change(key, 1);
            } else {
                exibeAlerta = true;
            }

            i += 1;
        }

        if (exibeAlerta) {
            toastr.warning('Atenção', 'Não foi possível marcar todos os dias escolhidos. Alguns dias já estão configurados para este mesmo horário.');
        }
    }

    desMarcarTodos() {
        for (var key of this.state.fields) {
            this.props.change(key, 0);
        }
    }

    handleChange(idx) {
        // O exibeAlerta retorna o resultado do switch acima, ou seja:
        // Se o dia já está marcado para outro plano no mesmo horário o exibeAlerta é inserido como true
        if (!this.validaTrocaPlano(idx)) {
            this.state.checked[idx] = this.state.checked[idx] === 1 ? 0 : 1;
            this.props.change(this.state.fields[idx], this.state.checked[idx]);
        } else {
            toastr.error('Erro', 'Já existe esse dia para este mesmo horário, impossível marcar.');
        }
    }

    handleChangePlano(select) {
        console.log(select);
        console.log(this.componentRef);
        let form = this.componentRef.current;

        if (select.currentTarget.value === '63') {
            for (let index = 1; index <= 4; index++) {
                form['anel' + index].removeAttribute('disabled');
                this.props.change('anel' + index, 1);
            }
        } else {
            for (let index = 1; index <= 4; index++) {
                form['anel' + index].setAttribute('disabled', 'disabled');
                this.props.change('anel' + index, 0);
            }
        }


        // O exibeAlerta retorna o resultado do switch acima, ou seja:
        // Se o dia já está marcado para outro plano no mesmo horário o exibeAlerta é inserido como true
    }

    onBlurHorarioInicial(event) {
        this.desMarcarTodos();
        toastr.info('Atenção', 'Devido à troca de horário os dias precisam ser configurados novamente.');
    }

    validaTrocaPlano(idx) {
        let list = this.props.list || [];
        let checked = this.state.checked[idx];

        // Cria uma variável de data atual para adicionar a hora e comparar
        let dataAtual = new Date();
        let dia = dataAtual.getDate();
        let mes = dataAtual.getMonth() + 1;
        // let ano = dataAtual.getFullYear();

        let exibeAlerta = false;

        if (dia < 10) {
            dia = '0' + dia
        }

        if (mes < 10) {
            mes = '0' + mes
        }

        for (let i = 0; i < list.length; i++) {
            if (checked === 0 || checked == undefined) {
                if ((list[i].horario_inicial === this.props.horario_inicial + ':00') && (parseInt(list[i].id) !== parseInt(this.refs.id.value))) {
                    switch (idx) {
                        case 0:
                            exibeAlerta = list[i].domingo === 1;
                            break;
                        case 1:
                            exibeAlerta = list[i].segunda === 1;
                            break;
                        case 2:
                            exibeAlerta = list[i].terca === 1;
                            break;
                        case 3:
                            exibeAlerta = list[i].quarta === 1;
                            break;
                        case 4:
                            exibeAlerta = list[i].quinta === 1;
                            break;
                        case 5:
                            exibeAlerta = list[i].sexta === 1;
                            break;
                        case 6:
                            exibeAlerta = list[i].sabado === 1;
                            break;
                        default:
                            exibeAlerta = true;
                    }
                }
            }

            if (exibeAlerta) {
                return exibeAlerta;
            }
        }

        return exibeAlerta;
    }

    handleClose() {
        this.setState({ show: false });
    }

    async salvarTroca() {

        this.setState({ show: true });

        await html2canvas(this.componentRef.current)
            .then((canvas) => {
                canvas.style.display = 'none';
                let image = canvas.toDataURL("png");
                this.props.change('imageLog', image);
            });

        await this.props.handleSubmit();

        this.setState({ show: false });
    }

    render() {
        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit } = this.props;
        let controlador_id = getConfig('cont');
        this.props.change('controlador_id', controlador_id);

        const requiredSelect = value => (((value === 'selecione') || (value == undefined)) ? 'Obrigatório' : undefined);

        let anelHabilitado = false;
        if (this.componentRef.current) {
            anelHabilitado = this.componentRef.current.plano_id.value === '63';
        }

        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false} >
                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <form onSubmit={handleSubmit} ref={this.componentRef}>
                    <div className='box-body' >
                        {/* <Field name='controlador_id' component={LabelAndInput}
                            label='Controlador' cols='12' readOnly={true} /> */}

                        <Field name='id' component={LabelAndInput} ref="id"
                            label='Código Tabela' cols='12' placeholder='Automático' readOnly={true} />

                        <SelectPlano
                            cols='12'
                            data-controlador-id={controlador_id}
                            data-plano-piscante={true}
                            validate={requiredSelect}
                            onChange={this.handleChangePlano.bind(this)}
                        />

                        <Field
                            type='time'
                            step="1"
                            component={LabelAndInput}
                            name='horario_inicial'
                            label='Horário'
                            props={{ onBlur: this.onBlurHorarioInicial.bind(this), format: "24hr" }}
                        />

                        <Grid cols='12'>
                            <Grid cols='2'>
                                <label className='checkbox'>Piscante:</label>
                            </Grid>
                            <Field cols='2' name="anel1" component={Checkbox} label='Anel 1' type='checkbox' disabled={ anelHabilitado ? '' : 'disabled' } />
                            <Field cols='2' name="anel2" component={Checkbox} label='Anel 2' type='checkbox' disabled={ anelHabilitado ? '' : 'disabled' } />
                            <Field cols='2' name="anel3" component={Checkbox} label='Anel 3' type='checkbox' disabled={ anelHabilitado ? '' : 'disabled' } />
                            <Field cols='2' name="anel4" component={Checkbox} label='Anel 4' type='checkbox' disabled={ anelHabilitado ? '' : 'disabled' } />
                        </Grid>
                    </div>

                    <div className='box box-primary'>
                        <div className='box-header'>
                            <h5 className='box-title'>Dias da Semana</h5>
                        </div>
                        {/* não funciona quando salva  */}
                        <Button type='button' className='default btn-xs' icon='check-square' label='Dias Úteis' onClick={this.diasUteis} />
                        <Button type='button' className='default btn-xs' icon='check-square' label='Final de Semana' onClick={this.finalSemana} />
                        <Button type='button' className='default btn-xs' icon='check-square' label='Marcar Todos' onClick={this.marcarTodos} />
                        <Button type='button' className='default btn-xs' icon='square' label='Desmarcar Todos' onClick={this.desMarcarTodos} />

                        <div className='box-body'>
                            <Field name="domingo" component={Checkbox} label='Domingo' type='checkbox' props={{ onChange: this.handleChange.bind(this, 0) }} />
                            <Field name='segunda' component={Checkbox} label='Segunda-feira' type='checkbox' props={{ onChange: this.handleChange.bind(this, 1) }} />
                            <Field name="terca" component={Checkbox} label='Terça-feira' type='checkbox' props={{ onChange: this.handleChange.bind(this, 2) }} />
                            <Field name="quarta" component={Checkbox} label='Quarta-feira' type='checkbox' props={{ onChange: this.handleChange.bind(this, 3) }} />
                            <Field name="quinta" component={Checkbox} label='Quinta-feira' type='checkbox' props={{ onChange: this.handleChange.bind(this, 4) }} />
                            <Field name="sexta" component={Checkbox} label='Sexta-feira' type='checkbox' props={{ onChange: this.handleChange.bind(this, 5) }} />
                            <Field name="sabado" component={Checkbox} label='Sabado' type='checkbox' props={{ onChange: this.handleChange.bind(this, 6) }} />
                        </div>
                    </div>

                    <div className='box-footer'>
                        {/* <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} /> */}
                        <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.salvarTroca} />
                        <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                    </div>
                </form>
            </div>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um
// form limpo para o update
TrocaPlanoForm = reduxForm({ form: 'trocaPlanoForm', destroyOnUnmount: false })(TrocaPlanoForm);

const selector = formValueSelector('trocaPlanoForm');
const mapStateToProps = state => ({
    controlador_id: selector(state, 'controlador_id') || 0,
    domingo: selector(state, 'domingo'),
    segunda: selector(state, 'segunda'),
    terca: selector(state, 'terca'),
    quarta: selector(state, 'quarta'),
    quinta: selector(state, 'quinta'),
    sexta: selector(state, 'sexta'),
    sabado: selector(state, 'sabado'),
    horario_inicial: selector(state, 'horario_inicial'),

    list: state.trocaPlanoCad.list
})

const mapDispatchToProps = dispatch => bindActionCreators({ init, getList }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TrocaPlanoForm);