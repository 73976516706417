import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import { init } from './adaptativoActions';

import { getIntervaloList, getListControlador, updateControlador } from '../adaptativo_intervalo/intervaloAdaptativoActions';
import { toastr } from 'react-redux-toastr';
import LabelAndInput from '../common/form/labelAndInput';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import Button from '../common/form/button';
import If from '../common/operator/if';
import SelectCodigo from './selectPlanoCodigo';
import swal from 'sweetalert2';
import IntervaloAdaptativoList from '../adaptativo_intervalo/intervaloAdaptativoList';
import ButtonTooltip from '../common/form/buttonTooltip';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class AdaptativoForm extends Component {

    constructor(props) {
        super(props);

        this.salvarAdaptativo = this.salvarAdaptativo.bind(this);
        this.cancelarAdaptativo = this.cancelarAdaptativo.bind(this);
        this.planoChange = this.planoChange.bind(this);
        this.geraIntervalo = this.geraIntervalo.bind(this);

        this.lerAdaptativoControlador = this.lerAdaptativoControlador.bind(this);
        this.salvaAdaptativoControlador = this.salvaAdaptativoControlador.bind(this);

        this.state = {
            htmlIntervalo: <div></div>,
            show: false,
            intervalos: []
        }
    }

    componentDidMount() {
        if (this.props.planoID) {
            this.geraIntervalo(this.props.planoID);
        }

    }

    planoChange(event) {

        if (event.target.value) {
            this.geraIntervalo(event.target.value);
        }
    }

    async geraIntervalo(plano_id) {

        this.setState({
            htmlIntervalo: <div>Carregando...</div>
        });

        await sleep(100);

        this.props.getIntervaloList(plano_id)
            .then((result) => {
                // tem que ter isso se não não funciona o insert, não carrega o array de dados para o "intervalo"
                this.props.valores.intervalo = result.payload.data;
                this.setState({ intervalos: result.payload.data });

                this.setState({
                    htmlIntervalo: <IntervaloAdaptativoList
                        // data-plano={event.target.value}
                        // data-total-fase={dataFase.length}
                        data-intervalos={this.state.intervalos}
                    />
                });
            })
    }

    async lerAdaptativoControlador() {

        this.setState({ show: true });
        this.setState({
            htmlIntervalo: <div>Carregando...</div>
        });

        await sleep(100);
                
        this.props.getListControlador(this.props.controladorID, this.props.valores.plano_id)
            .then((result) => {

                this.setState({ show: false });

                this.props.valores.intervalo = result.payload.data;
                this.setState({ intervalos: result.payload.data });

                this.setState({
                    htmlIntervalo: <IntervaloAdaptativoList
                        // data-plano={event.target.value}
                        // data-total-fase={dataFase.length}
                        data-intervalos={this.state.intervalos}
                    />
                });

            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', 'Sem conexão. Verifique se a central está conectada.');
                // this.props.getList(this.props['data-controlador'], this.props['data-plano']);
            });
    }

    salvaAdaptativoControlador() {
        // if (IntervaloValidacao.validaTempoZerado(this.props.list)) {
        swal({
            title: 'Enviar as configurações desse plano para a "Central"?',
            html: '<h5>Essa ação vai substituir as configurações existentes na central.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {

                this.setState({ show: true }); // da show no modal de aguarde

                const data = this.props.valores || [];

                this.props.updateControlador(data)
                    .then((result) => {
                        this.setState({ show: false });

                        if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                            toastr.error('Erro', result.payload.response.data.errors[0]);
                        } else {
                            toastr.success('Sucesso', 'Plano enviado com sucesso.');
                        }

                    })
                    .catch(e => {
                        this.setState({ show: false });
                        toastr.error('Erro', 'Sem conexão. Verifique se a central está conectada.');
                    });

            }
        })

    }

    cancelarAdaptativo() {
        this.setState({
            htmlIntervalo: <span></span>
        });
        this.props.init();
    }

    salvarAdaptativo() {

        if (!this.props.valid) {
            swal({
                type: 'warning',
                title: 'Informe os campos obrigatórios'
            })
        } else {

            this.props.valores.intervalo = this.state.intervalos;

            this.props.handleSubmit();

            this.setState({
                htmlIntervalo: <span></span>
            });
        }

    }

    handleClose() {
        this.setState({ show: false });
    }


    render() {
        const required = value => value ? undefined : 'Obrigatório';

        const requiredSelect = value => (((value === 'selecione') || (value == '') || (value == undefined)) ? 'Required' : undefined);
        // os eventos handleSubmit, vem do reduxForm        
        const { handleSubmit, readOnly } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className='box-body'>

                    <Modal
                        show={this.state.show}
                        onHide={this.handleClose}
                        dialogClassName='modal-load'
                        backdrop={false} >
                        <Modal.Body className='modal-body modal-load-body'>
                            <div >
                                <div >
                                    <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                                </div>
                                <div >
                                    <h4><b>Aguarde, processando...</b></h4>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <If condicao={!readOnly}>
                        <SelectCodigo
                            onChange={(event) => { this.planoChange(event) }}
                        />
                    </If>

                    <If condicao={readOnly}>
                        <Field name='plano_id' component={LabelAndInput} type='number'
                            label='Código Plano 2' cols='12' readOnly={true} />
                    </If>

                    <Field
                        name='velocidade_via'
                        type='number'
                        component={LabelAndInput}
                        label='Velocidade Via*'
                        cols='12'
                        placeholder='Informe a velocidade'
                        validate={required}
                    // readOnly={validaPermissaoReadOnly('CadPlano', 'descricao')}
                    />

                    <Field
                        name='tempo_extencao'
                        type='number'
                        component={LabelAndInput}
                        label='Tempo Extenção*'
                        cols='12'
                        placeholder='Informe o tempo extenção'
                        validate={required}
                    // readOnly={validaPermissaoReadOnly('CadPlano', 'descricao')}
                    />

                    <Field
                        name='dist_carro'
                        type='number'
                        component={LabelAndInput}
                        label='Distância Carro*'
                        cols='12'
                        placeholder='Informe a distância do carro'
                        validate={required}
                    // readOnly={validaPermissaoReadOnly('CadPlano', 'descricao')}
                    />

                    <Field
                        name='multiplicador'
                        type='number'
                        component={LabelAndInput}
                        label='Multiplicador*'
                        cols='12'
                        placeholder='Informe o multiplicador'
                        validate={required}
                    // readOnly={validaPermissaoReadOnly('CadPlano', 'descricao')}
                    />

                </div>

                <div className="panel panel-default">
                    <div className="panel-heading"><h4><b>Intervalos Plano</b></h4></div>
                    <div className="panel-body">
                        {this.state.htmlIntervalo}
                    </div>
                </div>
                <div className='box-footer'>
                    <ButtonTooltip type='button' className='success' icon='download' label='Ler Controlador' data-title='Busca os dados do controlador' onClick={this.lerAdaptativoControlador} />
                    <ButtonTooltip type='button' className='warning' icon='upload' label='Enviar Controlador' data-title='Envia os dados para o controlador' onClick={this.salvaAdaptativoControlador} />
                </div>
                <div className='box-footer'>
                    <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.salvarAdaptativo} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.cancelarAdaptativo} />
                </div>
            </form>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um 
// AdaptativoForm limpo para o update

AdaptativoForm = reduxForm({ form: 'adaptativoForm', destroyOnUnmount: false })(AdaptativoForm);
const selector = formValueSelector('adaptativoForm');
const mapStateToProps = state => ({
    controladorID: selector(state, 'controlador_id'),
    planoID: selector(state, 'plano_id'),
    valores: getFormValues('adaptativoForm')(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({ init, getIntervaloList, getListControlador, updateControlador }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdaptativoForm);