import DataLocal from '../configuracao/dataLocal';
import UserLocal from '../configuracao/userLocal';

const crypto = require('crypto');

export function validaPermissaoReadOnly(tela,campo) {
    return !validaPermissao(tela,campo);
}

export function validaPermissaoDisabled(tela,campo) {
    if (validaPermissao(tela,campo)){
        return '';
    } else {
        return 'disabled'
    }
}

function validaPermissao(tela,campo) {
    let permissao = false;
    if (tela) {
        let usuario = UserLocal.getUserConfig('email');
        let data = DataLocal.getData();
        let campo_t = crypto.createHash('sha1').update(tela).digest('hex');
        let valor_t0 = crypto.createHash('sha1').update(`${0}${usuario}${tela}`).digest('hex');
        // let valor_t1 = crypto.createHash('sha1').update(`${1}${usuario}${tela}`).digest('hex');

        

        if (campo) {
            // if (valor_t0 === data[campo_t]) {// se tiver permissão para alterar, verifica as permissões dos campos
            //     permissao = true;
            // }
            // permissao = false;
            let campo_c = crypto.createHash('sha1').update(`${tela}_${campo}`).digest('hex');
            let valor_c = crypto.createHash('sha1').update(`${1}${usuario}${tela}_${campo}`).digest('hex');

            if (valor_c === data[campo_c]) {
                permissao = true;
            }

        } else {
            if (valor_t0 === data[campo_t]) {// se tiver permissão para alterar, verifica as permissões dos campos
                permissao = true;
            }
        }
                    
    }

    return permissao;
}