import React from 'react';

export default props => (
 
    <select {...props.input}
        id={props.id}
        className={`form-control ${props.className}`}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
    >
        {props.children}
    </select>
   
)