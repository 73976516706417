import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showUpdate, remove, update } from './usuarioActions';
import { showNotificaoes } from './notificacoesUsuarioActions';
import SPButton from '../common/form/spButton';
import swal from 'sweetalert2';
import PermissaoUsuario from '../auth/permissao';

import ReactTableGT2A from '../common/grid/gridReactTable';

class UsuarioList extends Component {
    
    removerUsuario(data) {
        swal({
            title: 'Deseja realmente excluir?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.props.remove(data);
            }
        });
    }

    render() {

        const usuario = this.props.list || [];

        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <div>
                        <PermissaoUsuario tela="CadUsuario" condicao={true}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.props.showUpdate(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerUsuario(row.original)} />
                            <SPButton type='button' className='info btn-sm' icon='bell' data-title='Editar Notificações' onClick={() => this.props.showNotificaoes(row.original)} />

                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadUsuario" condicao={false}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' disabled="disabled" />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' disabled="disabled" />
                            <SPButton type='button' className='info btn-sm' icon='bell' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                ),
                className: 'column_icon',
                width: 120,
                filterable: true,
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Código',
                accessor: 'id',
                width: 60,
                filterable: true,
                className: 'column_grid column_center',
            },
            {
                Header: 'Nome',
                accessor: 'nome',
                className: 'column_grid',
                filterable: true,
                filterAll: true
            },
            {
                Header: 'Usuário',
                accessor: 'usuario',
                className: 'column_grid',
                filterable: true,
                filterAll: true
            },
            {
                Header: 'Grupo',
                accessor: 'grupo_idgrupo',
                className: 'column_grid',
                filterable: true,
                resizable: false,
                width: 70,
                filterAll: true
            },
            {
                Header: 'Ativo',
                accessor: 'ativo',
                width: 90,
                filterable: true,
                className: 'column_grid column_center',
                Cell: ({ value }) => (parseInt(value) === 1 ? "Sim" : "Não"),
                filterMethod: (filter, row) => {
                    if (filter.value === "Sim") {
                        return parseInt(row[filter.id]) === 1;
                    }
                    if (filter.value === "Não") {
                        return parseInt(row[filter.id]) === 0;
                    }
                    if (filter.value === "Todos") {
                        return true;
                    }

                },
                filterAll: true,
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "Todos"}
                    >
                        <option value="Todos">Todos</option>
                        <option value="Sim">Sim</option>
                        <option value="Não">Não</option>
                    </select>
            }
        ];

        return (
            <ReactTableGT2A
                data={usuario}
                columns={columns}
                className='-striped -highlight'
                defaultPageSize={15}
            />
        )
    }

}

const mapStateToProps = state => ({ list: state.usuarioCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ showUpdate, remove, update, showNotificaoes }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioList)
