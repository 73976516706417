import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='checkbox'>
            <label htmlFor={props.name}> 
            <input {...props.input}
                readOnly={props.readOnly}
                type={props.type}
                id={props.id}
                name={props.name}
                className={props.className}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                defaultChecked={props.defaultChecked}
            />
 
                {props.label}
                
            </label>
        </div>
    </Grid>
)