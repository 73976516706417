import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../form/labelAndSelectForm';
// import LabelAndSelectChange from '../form/labelAndSelectChange';

import { getGrupoUsuarioList } from './selectdbAtions';

class SelectGrupoUsuario extends Component {

    componentWillMount() {
        this.props.getGrupoUsuarioList();
    }

    renderOptions() {
        const list = this.props.list || [];
                     
        return list.map(grupo => (
            <option key={grupo.id} value={grupo.id}>{grupo.descricao}</option>
        ))
    }

    render() {
        this.renderOptions();
        return (
            <Field 
                name={this.props.name} 
                component={LabelAndSelectForm} 
                label={this.props.label} 
                cols={this.props.cols} 
                disabled={this.props.disabled} >
                {this.renderOptions()}
            </Field>
        )
    }    
}

const mapStateToProps = state => ({ list: state.grupoCad.list });
const mapDispatchToProps = dispatch => bindActionCreators({ getGrupoUsuarioList }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SelectGrupoUsuario);