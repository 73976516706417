import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    showUpdate
    , remove
    , getList
    , copiarTrocasControladores
    , getControladorList
    , removeTrocaPlanoControlador
    , createUpdateTrocaPlanoControlador
    , updateTrocaPlanoControlador
    , updateTrocaPlanoControladorGeral
} from './trocaPlanoActions';
import SPButton from '../common/form/spButton';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';

import ReactTableGT2A from '../common/grid/gridReactTable';
import ButtonTooltip from '../common/form/buttonTooltip';
import swal from 'sweetalert2';
import If from '../common/operator/if';
import PermissaoUsuario from '../auth/permissao';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class TrocaPlanoList extends Component {

    constructor(props) {
        super(props);

        this.copiarDadosControladores = this.copiarDadosControladores.bind(this);
        this.lerTrocaPlanoControlador = this.lerTrocaPlanoControlador.bind(this);
        this.salvarControladorDB = this.salvarControladorDB.bind(this);
        this.lerTrocaPlanoSistema = this.lerTrocaPlanoSistema.bind(this);
        this.salvarTrocaPlanoControlador = this.salvarTrocaPlanoControlador.bind(this);

        this.state = {
            salvarDB: false,
            show: false
        };
    }

    copiarDadosControladores() {
        swal({
            title: 'Enviar as "Tabelas de Troca" desse Controlador para os Controladores do mesmo grupo físico?',
            html: '<h5>Essa ação vai substituir as configurações existentes.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                const data = this.props.list || [];

                this.setState({ show: true });

                this.props.copiarTrocasControladores()
                    .then((result) => {
                        if (!result.error) {
                            this.props.updateTrocaPlanoControladorGeral(data)
                                .then((result2) => {
                                    this.setState({ show: false });

                                    if (result2.error) {
                                        toastr.warning('Atenção', result2.payload.response.data || 'Falha ao atualizar controladores');
                                    } else {
                                        toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                                    }
                                })
                                .catch(e2 => {
                                    this.setState({ show: false });
                                    toastr.warning('Atenção', e2.response && e2.response.data && e2.response.data.errors ? e2.response.data.errors.join('<br />') : 'Falha ao atualizar controladores');
                                });
                        } else {
                            toastr.warning('Atenção', result.payload.response.data || 'Falha ao realizar a cópia dos dados');
                        }
                    })
                    .catch(e => {
                        this.setState({ show: false });
                        toastr.warning('Atenção', e.response && e.response.data && e.response.data.errors ? e.response.data.errors.join('<br />') : 'Falha ao realizar a cópia dos dados');
                    });
            }
        })
    }

    handleClose() {
        this.setState({ show: false });
    }

    lerTrocaPlanoControlador() {
        this.setState({ show: true });

        this.props.getControladorList()
            .then((result) => {
                this.setState({ show: false });

                if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                    toastr.error('Erro', result.payload.response.data.errors[0]);
                    this.props.getList();
                } else {
                    toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                    this.setState({ salvarDB: true });
                }
            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', e.response.data.errors[0]);
                this.props.getList(this.props['data-controlador'], this.props['data-plano']);
            });
        // }
    }

    lerTrocaPlanoSistema() {
        this.props.getList();
        this.setState({ salvarDB: false });
    }

    async salvarControladorDB() {

        let confirma = false,
            cancela = true,
            message = 'Substituir os dados atuais do sistema pelos dados do controlador?',
            tipo = 'question',
            botao = 'Sim',
            valido = true;

        const data = this.props.list || [];

        for (let index = 0; index < data.length; index++) {
            if (!data[index].valido) {
                cancela = false;
                message = 'Cadastre o plano ' + data[index].plano_id + ' em Planos do Controlador';
                tipo = 'info';
                valido = false;
                botao = 'Fechar';
                break;
            }
            delete data[index].valido;
        }

        await swal({
            title: message,
            type: tipo,
            showCancelButton: cancela,
            confirmButtonColor: 'primary',
            confirmButtonText: botao,
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then((result) => {
            confirma = valido && result.value;
        });

        await sleep(1000);

        if (confirma) {
            this.setState({ show: true });
            this.setState({ salvarDB: false });
            
            if (data.length > 0) {
                
                this.props.removeTrocaPlanoControlador()
                    .then(async (result) => {
                        if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                            this.setState({ show: false });
                            toastr.error('Erro', result.payload.response.data.errors[0]);
                        } else {
                            for (var key in data) {
                                this.props.createUpdateTrocaPlanoControlador(data[key]);

                                // if (key == data.length - 1) {
                                //     this.props.getList();
                                // }
                            }
                            this.props.getList();
                            await sleep(1000);
                            this.setState({ show: false });
                        }
                    })
                    .catch(e => {
                        this.setState({ show: false });
                        toastr.error('Erro', e);
                    });
            } else {
                // faz o update na tabela selecao_automatica
                for (var key in data) {
                    this.props.createUpdateTrocaPlanoControlador(data[key]);
                }
                this.props.getList();
                await sleep(1000);
                this.setState({ show: false });
            }
        }
    }

    validaDiasTrocaPlano(data) {

        let semana = {
            dom: false,
            seg: false,
            ter: false,
            qua: false,
            qui: false,
            sex: false,
            sab: false
        };

        let diasSemPlano = '';

        for (const key in data) {
            if ((!semana.dom) && (parseInt(data[key].domingo) === 1)) {
                semana.dom = true;
            }
            if ((!semana.seg) && (parseInt(data[key].segunda) === 1)) {
                semana.seg = true;
            }
            if ((!semana.ter) && (parseInt(data[key].terca) === 1)) {
                semana.ter = true;
            }
            if ((!semana.qua) && (parseInt(data[key].quarta) === 1)) {
                semana.qua = true;
            }
            if ((!semana.qui) && (parseInt(data[key].quinta) === 1)) {
                semana.qui = true;
            }
            if ((!semana.sex) && (parseInt(data[key].sexta) === 1)) {
                semana.sex = true;
            }
            if ((!semana.sab) && (parseInt(data[key].sabado) === 1)) {
                semana.sab = true;
            }
        }

        if (!semana.dom) {
            diasSemPlano += '"Domingo", ';
        }
        if (!semana.seg) {
            diasSemPlano += '"Segunda", ';
        }
        if (!semana.ter) {
            diasSemPlano += '"Terça", ';
        }
        if (!semana.qua) {
            diasSemPlano += '"Quarta", ';
        }
        if (!semana.qui) {
            diasSemPlano += '"Quinta", ';
        }
        if (!semana.sex) {
            diasSemPlano += '"Sexta", ';
        }
        if (!semana.sab) {
            diasSemPlano += '"Sábado", ';
        }
        return diasSemPlano;

    }

    async salvarTrocaPlanoControlador() {
        let confirma = false;
        await swal({
            title: 'Enviar as configurações de trocas de plano para o controlador?',
            html: '<h5>Essa ação vai substituir as configurações existentes no controlador.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            confirma = result.value;
        });

        await sleep(1000);

        if (confirma) {

            this.setState({ show: true }); // da show no modal de aguarde
            const data = this.props.list || [];
            let diasSemPlano = this.validaDiasTrocaPlano(data);

            if (!diasSemPlano) {
                this.props.updateTrocaPlanoControlador(data)
                    .then((result) => {
                        this.setState({ show: false });

                        if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                            toastr.error('Erro', result.payload.response.data.errors[0]);
                        } else {
                            toastr.success('Sucesso', 'Trocas de Plano enviadas com sucesso.');
                        }

                    })
                    .catch(e => {
                        this.setState({ show: false });
                        toastr.error('Erro', e.response.data.errors[0]);
                    });
            } else {
                toastr.warning('Alerta', `O(s) dia(s) da semana: ${diasSemPlano} não tem plano cadastrado.`);
                this.setState({ show: false });
            }

        }
    }

    removerTrocaPlano(data) {
        if (data.id > 0) {
            swal({
                title: 'Deseja realmente excluir?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: 'primary',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: 'false'
            }).then((result) => {
                if (result.value) {
                    this.props.remove(data);
                }
            });
        } else {
            toastr.warning('Alerta', `Não é permitido exclusão ou alteração sem antes salvar no sistema. Clique no botão "Salvar no sistema" primeiro`);
        }
    }

    alterarTrocaPlano(data) {
        if (data.id > 0) {
            this.props.showUpdate(data)
        } else {
            toastr.warning('Alerta', `Não é permitido exclusão ou alteração sem antes salvar no sistema. Clique no botão "Salvar no sistema" primeiro`);
        }
    }

    render() {

        const trocaPlano = this.props.list || [];

        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <div>
                        <PermissaoUsuario tela="CadTrocaPlano" condicao={true}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.alterarTrocaPlano(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerTrocaPlano(row.original)} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadTrocaPlano" condicao={false}>
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' disabled='disabled' />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' disabled='disabled' />
                        </PermissaoUsuario>
                    </div>
                ),
                width: 90,
                filterable: true,
                className: 'column_icon',
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Plano',
                accessor: 'plano_tratado',
                width: 50,
                className: 'column_grid column_center',
                filterable: true
            },
            {
                Header: 'Tabela',
                accessor: 'id',
                width: 50,
                className: 'column_grid column_center',
                filterable: true
            },
            {
                Header: 'Dom',
                accessor: 'domingo',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Seg',
                accessor: 'segunda',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Ter',
                accessor: 'terca',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Qua',
                accessor: 'quarta',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Qui',
                accessor: 'quinta',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Sex',
                accessor: 'sexta',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Sab',
                accessor: 'sabado',
                width: 40,
                className: 'column_grid column_center',
                Cell: ({ value }) => (value == 1 ? "Sim" : "Não")
            },
            {
                Header: 'Horário',
                accessor: 'horario_inicial',
                className: 'column_grid column_center',
                width: 100
            }
        ];

        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false} >
                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <ReactTableGT2A
                    data={trocaPlano}
                    columns={columns}
                    className='-striped -highlight'
                    defaultPageSize={15}
                />
                <div className='box-footer'>
                    <PermissaoUsuario tela="CadTrocaPlano" condicao={true}>
                        <ButtonTooltip type='button' id='btnDataLerControlador' className='success' icon='download' label='Ler Controlador' data-title='Busca os dados do controlador' onClick={this.lerTrocaPlanoControlador} />
                        <ButtonTooltip type='button' id='btnDataEnviarControlador' className='warning' icon='upload' label='Enviar Controlador' data-title='Envia os dados para o controlador' onClick={this.salvarTrocaPlanoControlador} />
                        <ButtonTooltip type='button' id='btnDataReplicarTrocaPlano' className='primary' icon='copy' label='Enviar para Todos' data-title='Replica a troca de plano para os controladores do grupo físico' onClick={this.copiarDadosControladores} />
                    </PermissaoUsuario>

                    <PermissaoUsuario tela="CadTrocaPlano" condicao={false}>
                        <ButtonTooltip type='button' id='btnDataLerControlador' className='success' icon='download' label='Ler Controlador' disabled='disabled' />
                        <ButtonTooltip type='button' id='btnDataEnviarControlador' className='warning' icon='upload' label='Enviar Controlador' disabled='disabled' />
                        <ButtonTooltip type='button' id='btnDataReplicarTrocaPlano' className='primary' icon='copy' label='Enviar para Todos' disabled='disabled' />
                    </PermissaoUsuario>

                    <If condicao={this.state.salvarDB}>
                        <PermissaoUsuario tela="CadTrocaPlano" condicao={true}>
                            <ButtonTooltip type='button' id='btnDataTrocaPlanoSalvarDB' className='primary' icon='check' label='Salvar no sistema' data-title='Salva no sistema os dados que viaram do controlado' onClick={this.salvarControladorDB} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadTrocaPlano" condicao={false}>
                            <ButtonTooltip type='button' id='btnDataTrocaPlanoSalvarDB' className='primary' icon='check' label='Salvar no sistema' disabled='disabled' />
                        </PermissaoUsuario>
                    </If>
                    <If condicao={this.state.salvarDB}>
                        <PermissaoUsuario tela="CadTrocaPlano" condicao={true}>
                            <ButtonTooltip type='button' id='btnTrocaPlanoLerSistema' className='primary' icon='file-download' label='Ler do sistema' data-title='Busca os dados do banco de dados do sistema' onClick={this.lerTrocaPlanoSistema} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadTrocaPlano" condicao={false}>
                            <ButtonTooltip type='button' id='btnTrocaPlanoLerSistema' className='primary' icon='file-download' label='Ler do sistema' disabled='disabled' />
                        </PermissaoUsuario>
                    </If>
                </div>
            </div>
        )
    }
}
// busca o planoCad la do reducers.js
const mapStateToProps = state => ({ list: state.trocaPlanoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({
    showUpdate
    , remove
    , getList
    , copiarTrocasControladores
    , getControladorList
    , removeTrocaPlanoControlador
    , createUpdateTrocaPlanoControlador
    , updateTrocaPlanoControlador
    , updateTrocaPlanoControladorGeral
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(TrocaPlanoList)
