import configLocal from '../configuracao/configLocal';
import moment from 'moment';
import axiosInstance from '../main/axios';

export function getSummary() {
    const request = [];
    return {
        type: 'BILLING_SUMMARY_FETCHED',
        payload: request
    }
}

export function getDadosDashboard() {
    var contId = configLocal.getConfig('cont');
    var data = moment().format("YYYY-MM-DD");   

    const request = axiosInstance.get(`/dashboard/dados/${contId}/${data}`);    

    return {
        type: '',
        payload: request
    }  
}
