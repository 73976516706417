import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import { getList, update } from './faseActions'

import List from './faseList';

class Fase extends Component {

    componentWillMount() {
        this.props.getList();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Fases' small='Cadastro' />
                <Content>

                    <List />

                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getList, update }, dispatch)
export default connect(null, mapDispatchToProps)(Fase)