import React from 'react';

export default props => (

    <label className={`checkbox-inline `} htmlFor={props.name}>
        <input {...props.input}
            readOnly={props.readOnly}
            type={props.type}
            id={props.id}
            name={props.name}
            className={props.className}
            checked={props.checked}
            onChange={props.onChange}
            onLoad={props.onLoad}
            disabled={props.disabled}
        />

        {props.label}

    </label>


)