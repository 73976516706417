import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import { getConfig, setConfig } from '../configuracao/configLocal';

const INITIAL_VALUES = {}

export function getList() {
    const request = axiosInstance.get(`/gruposfisicos`)

    return {
        type: 'GRUPO_FISICO_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submitDelete(values);
    // return submit(values, 'delete');
}

function submit(values, method) {
    let grupo_id = parseInt(values.id);
    // let conf_grupo = getConfig('grupo');

    return dispatch => {
        const id = values.id ? values.id : ''
        axiosInstance[method](`/gruposfisicos/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                setConfig('grupo', grupo_id);
                dispatch(init());
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

function submitDelete(values) {
    let grupo_id = parseInt(values.id);
    let conf_grupo =  getConfig('grupo');

    let data = {data: values };// tem que mandar como {data: {}} para o axios delete receber algo no body, se não mostra nada

    return dispatch => {
        const id = values.id ? values.id : ''
        axiosInstance.delete(`/gruposfisicos/${id}`, data)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init());

                if (grupo_id === conf_grupo) {
                    setConfig('grupo', 0);
                }
            })
            .catch(e => {
                let erro = e.response.data.errors;

                if (erro[0].indexOf('Cannot delete or update a parent row: a foreign key') != -1) {
                    toastr.warning('Erro', 'Existem um ou mais controladores ligados a este grupo físico. Todos devem ser removidos antes de excluir o grupo.');
                } else {
                    erro.forEach(error => toastr.error('Erro', error))
                }
            })
    }
}

export function showUpdate(grupoFisico) {

    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('grupoFisicoForm', grupoFisico)
    ]
}

export function showDelete(grupoFisico) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('grupoFisicoForm', grupoFisico)
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('grupoFisicoForm', INITIAL_VALUES)
    ]
}
