import React, { Component } from 'react';
import { Field } from 'redux-form';
import checkboxFase from '../common/form/checkboxFase';
import If from '../common/operator/if';

class FaseConflitoControlador extends Component {

    constructor(props) {
        super(props);
        this.geralFasesConflito = this.geralFasesConflito.bind(this);
    }

    geralFasesConflito() {
        let faseGrupoObj = [];
        let fasesIni = parseInt(this.props.numeroFasesIni)+1;
        let fasesFin = this.props.numeroFases;

        for (let idxFase = fasesIni; idxFase <= fasesFin; idxFase++) {
            let faseObj = [];
            for (let idx = fasesIni; idx <= idxFase; idx++) {
                faseObj.push(<Field name={`fase${idx - 1}_fase${idxFase}`} key={`fase${idx - 1}_fase${idxFase}`} component={checkboxFase} label={`F${idx - 1} c/ F${idxFase}`} type='checkbox' disabled={this.props.disabled} />);
            }
            faseGrupoObj.push(
                <div key={`fase${idxFase}`}>
                    {faseObj}
                </div>
            );
        }

        return faseGrupoObj;
    }

    render() {

        return (
            <div>
                <div className='form-group form-group-sm'>
                    <label className='label-gt' htmlFor='fase1_fase2'>Fases não conflitantes:</label>
                </div>
                {this.geralFasesConflito()}
            </div>
        )
    }
}

export default FaseConflitoControlador;