import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { init } from './controladorActions';
import ListSAP from '../sap/selecaoAutomaticaList';

class ControladorSAP extends Component {

    render() {

        const { controladorID } = this.props;        
        
        return (
            <div>
                <ListSAP 
                    data-controlador={controladorID}
                />
            </div>
        )
    }
}

const selector = formValueSelector('controladorSAP');
const mapStateToProps = state => ({
    controladorID: selector(state, 'id'),
    sap_detector: selector(state, 'sap_detector'),
    sap_tempo_amostragem: selector(state, 'sap_tempo_amostragem'),
    sap_capacidade_faixa: selector(state, 'sap_capacidade_faixa'),
    sap_sentido1: selector(state, 'sap_sentido1'),
    sap_sentido2: selector(state, 'sap_sentido2'),
    sap_sentido3: selector(state, 'sap_sentido3'),
    sap_sentido4: selector(state, 'sap_sentido4'),
    sap_laco_sentido1: selector(state, 'sap_laco_sentido1'),
    sap_laco_sentido2: selector(state, 'sap_laco_sentido2'),
    sap_laco_sentido3: selector(state, 'sap_laco_sentido3'),
    sap_laco_sentido4: selector(state, 'sap_laco_sentido4')
})

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ControladorSAP);