import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, create, update, generateToken } from './identificadorTelegramActions'

import PermissaoUsuario from '../auth/permissao';

import List from './identificadorTelegramList';
import Form from './identificadorTelegramForm';
import FormToken from './identificadorTelegramFormToken';

class IdentificadorTelegram extends Component {

    componentWillMount() {
        this.props.init(); 
    }

    render() {
        return (
            <div>
                <ContentHeader title='Identificador Telegram' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                            <PermissaoUsuario tela="CadIdentificadorTelegram" condicao={true}>
                                <TabHeader label='Incluir' icon='plus' target='tabCreate' />
                                <TabHeader label='Alterar' icon='pencil-alt' target='tabUpdate' />
                                <TabHeader label='Gerar Token' icon='plus' target='tabGenerate' />
                            </PermissaoUsuario>
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List />
                            </TabContent>
                            <TabContent id='tabCreate'>
                                <Form onSubmit={this.props.create} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                            <TabContent id='tabUpdate'>
                                <Form onSubmit={this.props.update} readOnly={true} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>

                            <TabContent id='tabGenerate'>
                                <FormToken onSubmit={this.props.generateToken} submitClass='primary' submitLabel='Gerar' />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, create, update, generateToken }, dispatch)
export default connect(null, mapDispatchToProps)(IdentificadorTelegram)