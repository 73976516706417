import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LabelAndSelectChange from '../form/labelAndSelectChange';

import { getControladorList } from './selectdbAtions';

class SelectAllControlador extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }
    }

    componentWillMount() {
        this.props.getControladorList();
    }

    renderOptions() {
        const list = this.props.list || [];
        var listCont = [];

        list.forEach(cont => {
            if (this.props['data-grupofisico-id'] == cont.grupo_fisico_id) {
                if (this.props['data-anel-id']) {// se existir o parametro data-anel-id tem que filtrar o anel
                    if (this.props['data-anel-id'] == cont.anel)
                        listCont.push(cont);

                } else
                    listCont.push(cont);
            }
        });

        return listCont.map(contro => (
            <option key={contro.id} value={contro.id}>{contro.numero_controlador} - {contro.endereco} </option>
        ))
    }

    render() {

        return (

            <LabelAndSelectChange
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                cols={this.props.cols}
                onChange={(event) => {
                    this.setState({ value: event.target.value });
                    this.props.onChange(event);
                }}
                value={this.state.value}
            >
                <option value="0">Todos do Grupo Físico</option>
                {this.renderOptions()}
            </LabelAndSelectChange>

        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.listHeader })
const mapDispatchToProps = dispatch => bindActionCreators({ getControladorList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectAllControlador)