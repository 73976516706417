import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContagemGraficoList } from './contagemActions';
import { Line } from 'react-chartjs-2';
import moment from 'moment';;

var optionsGrafico = {
    responsive: true,
    maintainAspectRatio: false,
    scaleStartValue: 0,
    scales: {
        yAxes: [{
            ticks: {
                suggestedMin: 0
            }
        }]
    },
    tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        titleFontStyle: "normal",
        titleFontSize: 14,
        bodyFontSize: 14
    },
    layout: {
        padding: {
            left: 5,
            right: 0,
            top: 0,
            bottom: 0
        }
    },
    legend: {
        display: true,
        position: 'top',
    },
    title: {
        display: false
    }
}

class GraficoContagemControlador extends Component {

    constructor(props) {
        super(props);

        this.contagemVolumetrica = this.contagemVolumetrica.bind(this);

        this.intervalGraficoContagemControlador = 0;

        this.state = {
            loop: 0
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalGraficoContagemControlador);
    }

    componentDidMount() {
        this.intervalGraficoContagemControlador = setInterval(() => {

            try {
                if (this.state.loop === 0) {
                    this.setState({ loop: 1 });

                    this.props.getContagemGraficoList(this.props.filtrosGrafico)
                        .then((result) => {
                            this.setState({ loop: 0 });
                        });
                }
            } catch (error) {

            }
        }, 10000);
    }

    contagemVolumetrica() {
        const contagemVolu = this.props.listContagem || [];
        let datasetContagem = [];
        let resultData = {};
        let dataLaco = [];
        let dataLabels = [];
        let corLinha = ['#00B560', '#FD5D26', '#F6A400', '#00B9F7', '#3983C2', '#81278e', '#00a65a', '#a0300b'];
        let totalLaco = 0;

        dataLabels = [];
        dataLaco = [];
        for (const key in contagemVolu) {
            totalLaco = 0;
            for (let index = 1; index <= 8; index++) {
                totalLaco += parseInt(contagemVolu[key][`laco_${index}`]);
            }

            dataLaco.push(totalLaco);
            dataLabels.push(moment(contagemVolu[key].data_contagem).format('DD/MM/YYYY') + ' ' + contagemVolu[key].hora_contagem);
        }

        datasetContagem.push(
            {
                label: `Total de veículos`,
                backgroundColor: corLinha[0],
                borderColor: corLinha[0],
                borderWidth: 3,
                fill: false,
                data: dataLaco
            }
        );

        resultData = {
            labels: dataLabels,
            datasets: datasetContagem
        };

        return resultData;
    }

    render() {
        const contagem = this.props.listContagem || [];

        const data = {
            labels: [],
            datasets: []
        };

        if (contagem.length > 0) {
            return (
                <Line
                    data={this.contagemVolumetrica}
                    width={100}
                    height={500}
                    options={optionsGrafico}
                />
            )
        } else {
            return (
                <Line
                    data={data}
                    width={100}
                    height={500}
                    options={optionsGrafico}
                />
            );
        }
    }
}

const mapStateToProps = state => ({ listContagem: state.contagemaList.listContagem })
const mapDispatchToProps = dispatch => bindActionCreators({ getContagemGraficoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(GraficoContagemControlador)