import React, { Component } from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
import Grid from '../layout/grid'

// export default props => (
//     <Grid cols={props.cols}>
//         <div className='form-group form-group-sm'>
//             <label className='label-gt' htmlFor={props.name}>{props.label}</label>
//             <select {...props.input}
//                 className="form-control"
//                 placeholder={props.placeholder}
//                 readOnly={props.readOnly}
//                 onChange={props.onChange}
//                 // defaultValue={props.defaultValue}
//                 value={props.value}
//             >

//                 {props.children}

//             </select>
//         </div>
//     </Grid>
// )

class LabelAndSelectChange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        }

    }

    render() {

        return (

            <Grid cols={this.props.cols}>
                <div className='form-group form-group-sm'>
                    <label className='label-gt' htmlFor={this.props.name}>{this.props.label}</label>
                    <select {...this.props.input}
                        id={this.props.id}
                        name={this.props.name}
                        className="form-control"
                        placeholder={this.props.placeholder}
                        readOnly={this.props.readOnly}
                        onChange={this.props.onChange}
                        // defaultValue={props.defaultValue}
                        value={this.props.value}
                    >

                        {this.props.children}

                    </select>
                </div>
            </Grid>

        )
    }
}

// const mapDispatchToProps = dispatch => bindActionCreators({ getGrupoFisicoList }, dispatch)
export default LabelAndSelectChange;// connect(null, mapDispatchToProps)(LabelAndSelectChange)