const INITIAL_STATE = {
    list: [],
    total: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'USER_LOG_FETCHED':
            return { ...state, list: action.payload.data.list, total: action.payload.data.total }
        case 'USER_LOG_IMAGE_FETCHED':
            return { ...state, image: action.payload.data.image }
        default:
            return state
    }
}