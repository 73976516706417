import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import { getListPlano } from './adaptativoActions';

class SelectIPlanoNovo extends Component {

    componentWillMount() {
        this.props.getListPlano();
    }

    renderRows() {

        const list = this.props.listPlano || [];

        return list.map(plano => (
            <option key={plano.id} value={plano.id}>{plano.descricao}</option>
        ))
    }

    render() {

        return (

            // <LabelAndSelectChange
            //     name={this.props.name}
            //     label={this.props.label}
            //     cols={this.props.cols}
            //     validate={this.props.validate}
            //     onChange={this.props.onChange}
            //     value={this.props.value}
            // >
            //     <option value="selecione">Selecione</option>
            //     {this.renderRows()}
            // </LabelAndSelectChange>

            <Field
                name="plano_id"
                component={LabelAndSelectForm}
                label='Código Plano*'
                validate={this.props.validate}
                onChange={this.props.onChange}
            >
                <option value="selecione">Selecione</option>
                {this.renderRows()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ listPlano: state.adaptativoCad.listPlano })
const mapDispatchToProps = dispatch => bindActionCreators({ getListPlano }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectIPlanoNovo)