import axiosInstance from '../main/axios';
import configLocal from '../configuracao/configLocal';

export function getContagemGraficoList(filtro) {
    var contId = configLocal.getConfig('cont');
    const dataIni = filtro.dataInicial;
    const dataFim = filtro.dataFinal;

    const request = axiosInstance.get(`/dashboard/contagem/${contId}/${dataIni}/${dataFim}`);
    
    return {
        type: 'GRAFICO_CONTAGEM_FETCHED',
        payload: request
    }
}
