import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import { showUpdate, remove, limpa, showIntervalo, getList, create } from './planoActions';
import { getListInterval, update } from '../intervalo/intervaloActions';
import SPButton from '../common/form/spButton';

import ReactTableGT2A from '../common/grid/gridReactTable';
import '../common/grid/grid.css';
import swal from 'sweetalert2';
import PermissaoUsuario from '../auth/permissao';
import ButtonTooltip from '../common/form/buttonTooltip';
import { toastr } from 'react-redux-toastr';
import { getConfig, setConfig } from '../configuracao/configLocal';
import { Modal } from 'react-bootstrap';
import If from '../common/operator/if';
import { lerAneis } from '../controlador/controladorActions';

class PlanoList extends Component {

    constructor(props) {
        super(props);

        this.intervalGetList = 0;

        this.removerPlano = this.removerPlano.bind(this);
        this.limparPlano = this.limparPlano.bind(this);
        this.alterarPlano = this.alterarPlano.bind(this);
        this.configurarPlano = this.configurarPlano.bind(this);
        this.lerPlanoControlador = this.lerPlanoControlador.bind(this);
        this.salvarPlanoDB = this.salvarPlanoDB.bind(this);
        this.lerPlanoSistema = this.lerPlanoSistema.bind(this);

        this.state = {
            show: false,
            planoList: [],
            intervaloList: [],
            salvarDB: false,
            modalAnel: ''
        };
    }

    async salvarPlanoDB() {
        await swal({
            title: 'Substituir os dados atuais do sistema pelos dados do controlador?',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            allowOutsideClick: 'false'
        }).then(async (result) => {
            if (result.value) {
                this.setState({ show: true });

                let planos = this.state.planoList,
                    controladorAux = 0;

                while (planos.length > 0) {
                    let plano = planos.shift(),
                        intervalos = plano.intervalos,
                        controlador = plano.controlador_id;

                    if (controlador !== controladorAux) {
                        await this.props.remove({
                            controlador_id: controlador
                        });
                        controladorAux = controlador;
                    }

                    delete plano.intervalos;

                    setConfig('anel', plano.anel);

                    await this.props.create(plano);

                    for (let key in intervalos) {
                        await this.props.update(intervalos[key]);
                    }
                }

                this.setState({ show: false, planoList: [], salvarDB: false });
            }
        });
    }

    lerPlanoSistema() {
        this.setState({ planoList: [], salvarDB: false });
        this.props.getList();
    }

    async lerPlanoControlador() {

        this.setState({ show: true });
        var numCont = getConfig('numCont'),
            grupo = getConfig('grupo'),
            encontrado = false,
            planoList = [];

        await this.props.lerAneis(numCont, grupo).then(async ()=>{
            for (let i in this.props.aneis) {
                let plano = 0,
                    item = this.props.aneis[i];

                this.setState({ modalAnel: item.anel });
                do {
                    encontrado = await this.getPlano(item.id, ++plano);
                    if (encontrado) {
                        let values = encontrado[0];

                        planoList.push({
                            anel: item.anel,
                            controlador_id: values.controlador_id,
                            id: values.plano_id,
                            descricao: 'Plano ' + plano,
                            status: 1,
                            modo_operacao_id: values.modo_operacao_id,
                            numero_intervalo: values.numero_intervalo,
                            ciclo_maximo: values.ciclo_maximo,
                            defasagem: values.defasagem,
                            atuador_var: values.atuador_var,
                            intervalos: encontrado
                        });

                        this.setState({ planoList: planoList, salvarDB: true });
                    }
                } while (encontrado);
            }
        });

        this.setState({ show: false, modalAnel: '' });
    }

    async getPlano(cont, plano) {
        return new Promise(resolve => {
            this.props.getListInterval(cont, plano)
                .then((result) => {
                    if (!result.error) {
                        toastr.success('Sucesso', 'Plano ' + plano + ' lido com sucesso.');
                        resolve(result.payload.data);
                    } else {
                        toastr.error('Erro', result.payload.response.data.errors[0]);
                        resolve(false);
                    }

                })
                .catch(e => {
                    resolve(false);
                    toastr.info('Atenção', 'Plano ' + plano + ' não encontrado!');
                });

        });
    }

    removerPlano(data) {
        if (!this.state.salvarDB) {
            swal({
                title: 'Deseja realmente excluir?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: 'primary',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: 'false'
            }).then((result) => {
                if (result.value) {
                    this.props.remove(data);
                }
            });
        } else {
            toastr.warning('Alerta', `Não é permitido exclusão sem antes salvar no sistema. Clique no botão "Salvar no sistema" primeiro`);
        }
    }

    limparPlano(data) {
        if (parseInt(data.possui_troca) === 1) {
            toastr.warning('Alerta', `Não é permitido exclusão pois existe Tabela de Troca para este plano!`);
        } else if (!this.state.salvarDB) {
            swal({
                title: 'Deseja realmente excluir do sistema e do controlador?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: 'primary',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: 'false'
            }).then((result) => {
                if (result.value) {
                    let res = this.props.limpa(data);
                    console.log(res);
                    res.then((sucess)=>{
                        this.props.remove(data);
                    });
                }
            });
        } else {
            toastr.warning('Alerta', `Não é permitido exclusão sem antes salvar no sistema. Clique no botão "Salvar no sistema" primeiro`);
        }
    }

    alterarPlano(data) {
        if (!this.state.salvarDB) {
            this.props.showUpdate(data);
        } else {
            toastr.warning('Alerta', `Não é permitido alteração sem antes salvar no sistema. Clique no botão "Salvar no sistema" primeiro`);
        }
    }

    configurarPlano(data) {
        if (!this.state.salvarDB) {
            window.$('#selectConfControlador').val(data.controlador_id).get(0).dispatchEvent(new Event('change', {bubbles: true}));
            this.props.showIntervalo(data);
        } else {
            toastr.warning('Alerta', `Não é permitido configurar plano sem antes salvar no sistema. Clique no botão "Salvar no sistema" primeiro`);
        }
    }

    render() {
        const planoDB = this.props.list;
        let plano = this.state.planoList;
        if (plano.length === 0) {
            plano = planoDB || [];
        }

        const columns = [
            {
                Header: 'Ações',
                Cell: row => (
                    <div>
                        <PermissaoUsuario tela="CadPlano" condicao={true}>
                            <SPButton type='button' className='success btn-sm' icon='cog' data-title='Configurar Plano' onClick={() => this.configurarPlano(row.original)} />
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' data-title='Alterar' onClick={() => this.alterarPlano(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' data-title='Excluir' onClick={() => this.removerPlano(row.original)} />
                            <SPButton type='button' className='danger btn-sm' icon='upload' data-title='Excluir e Limpar do Controlador' onClick={() => this.limparPlano(row.original)} disabled={row.original.id !== row.original.ultimo_plano} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadPlano" condicao={false}>
                            <SPButton type='button' className='success btn-sm' icon='cog' data-title='Configurar Plano' onClick={() => this.configurarPlano(row.original)} />
                            <SPButton type='button' className='warning btn-sm' icon='pencil-alt' disabled="disabled" />
                            <SPButton type='button' className='danger btn-sm' icon='trash-alt' disabled="disabled"/>
                            <SPButton type='button' className='danger btn-sm' icon='upload' data-title='Excluir e Limpar do Controlador' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                ),
                width: 160,
                filterable: true,
                className: 'column_icon',
                Filter: () => <div className='rt-th'><span >Filtrar</span></div>
            },
            {
                Header: 'Plano',
                accessor: 'id',
                width: 50,
                className: 'column_grid column_center',
                filterable: true
            },
            {
                Header: 'Anel',
                accessor: 'anel',
                width: 50,
                className: 'column_grid column_center',
                filterable: true
            },
            {
                Header: 'Descrição',
                accessor: 'descricao',
                className: 'column_grid',
                filterable: true
            },
            {
                Header: 'Operação',
                className: 'column_grid',
                accessor: 'modo_operacao_desc'
            },
            {
                Header: 'Intervalos',
                accessor: 'numero_intervalo',
                className: 'column_grid column_center',
                width: 70
            }
        ];

        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false}
                >
                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando anel {this.state.modalAnel}...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <ReactTableGT2A
                    data={plano}
                    columns={columns}
                    className='-striped -highlight'
                    defaultPageSize={15}
                />

                <div className='box-footer'>
                    <PermissaoUsuario tela="CadIntervaloPlano" campo="ler_controlador" condicao={true}>
                        <ButtonTooltip type='button' id='btnLerControlador' className='success' icon='download' label='Ler Controlador' data-title='Busca os dados do controlador' onClick={this.lerPlanoControlador} />
                    </PermissaoUsuario>

                    <If condicao={this.state.salvarDB}>
                        <PermissaoUsuario tela="CadPlano" condicao={true}>
                            <ButtonTooltip type='button' id='btnDataPlanoSalvarDB' className='primary' icon='check' label='Salvar no sistema' data-title='Salva no sistema os dados que viaram do controlador' onClick={this.salvarPlanoDB} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadPlano" condicao={false}>
                            <ButtonTooltip type='button' id='btnDataPlanoSalvarDB' className='primary' icon='check' label='Salvar no sistema' disabled='disabled' />
                        </PermissaoUsuario>
                    </If>
                    <If condicao={this.state.salvarDB}>
                        <PermissaoUsuario tela="CadPlano" condicao={true}>
                            <ButtonTooltip type='button' id='btnPlanoLerSistema' className='primary' icon='file-download' label='Ler do sistema' data-title='Busca os dados do banco de dados do sistema' onClick={this.lerPlanoSistema} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadPlano" condicao={false}>
                            <ButtonTooltip type='button' id='btnPlanoLerSistema' className='primary' icon='file-download' label='Ler do sistema' disabled='disabled' />
                        </PermissaoUsuario>
                    </If>
                </div>
            </div>
        )
    }
}
// busca o planoCad la do reducers.js
const mapStateToProps = state => ({ list: state.planoCad.list, aneis: state.controladorCad.aneis })
const mapDispatchToProps = dispatch => bindActionCreators({ showUpdate, remove, limpa, showIntervalo, getList, getListInterval, create, update, lerAneis }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PlanoList)
