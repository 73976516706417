import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContagemGraficoList } from './contagemActions';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

var optionsGrafico = {
    responsive: true,
    maintainAspectRatio: false,
    scaleStartValue: 0,
    scales: {
        yAxes: [{
            ticks: {
                suggestedMin: 0
            }
        }]
    },
    tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        titleFontStyle: "normal",
        titleFontSize: 14,
        bodyFontSize: 14
    },
    layout: {
        padding: {
            left: 5,
            right: 0,
            top: 0,
            bottom: 0
        }
    },
    legend: {
        display: true,
        position: 'top',
    },
    title: {
        display: false
    }
}

class GraficoContagemLaco extends Component {

    constructor(props) {
        super(props);

        this.contagemVolumetrica = this.contagemVolumetrica.bind(this);
    }

    componentWillMount() {
        this.props.getContagemGraficoList(this.props.filtrosGrafico);
    }

    getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    contagemVolumetrica() {
        let contagemVolu = this.props.listContagem || [];
        let datasetContagem = [];
        let resultData = {};
        let dataLaco = [];
        let dataLabels = [];
        // let corLinha = ['#00B560', '#FD5D26', '#F6A400', '#00B9F7', '#3983C2', '#81278e', '#00a65a', '#a0300b'];

        let corLinha = [
            "#3AC962",
            "#3903FB",
            "#AEC0AD",
            "#90AA25",
            "#38A313",
            "#53A1FF",
            "#72EC23",
            "#C23980",
            "#07C14D",
            "#11F48C",
            "#FA683D",
            "#CA7E44",
            "#CC5956",
            "#41116B",
            "#D63610",
            "#9A72CE"
        ]

        // let corLinha = [
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor(),
        //     this.getRandomColor()
        // ]

        for (let index = 1; index <= 16; index++) {

            dataLabels = [];
            dataLaco = [];

            for (const key in contagemVolu) {
                dataLaco.push(contagemVolu[key][`laco_${index}`]);
                dataLabels.push(moment(contagemVolu[key].data_contagem).format('DD/MM/YYYY') + ' ' + contagemVolu[key].hora_contagem);
            }

            datasetContagem.push(
                {
                    label: `Laço ${index}`,
                    backgroundColor: corLinha[index - 1],
                    borderColor: corLinha[index - 1],
                    borderWidth: 3,
                    fill: false,
                    data: dataLaco
                }
            );
        }

        resultData = {
            labels: dataLabels,
            datasets: datasetContagem
        };

        return resultData;
    }

    render() {
        const contagem = this.props.listContagem || [];
        const data = {
            labels: [],
            datasets: []
        };

        if (contagem.length > 0) {
            return (
                <Line
                    data={this.contagemVolumetrica}
                    width={100}
                    height={500}
                    options={optionsGrafico}
                />
            )
        } else {
            return (
                <Line
                    data={data}
                    width={100}
                    height={500}
                    options={optionsGrafico}
                />
            );
        }
    }
}

const mapStateToProps = state => ({ listContagem: state.contagemaList.listContagem })
const mapDispatchToProps = dispatch => bindActionCreators({ getContagemGraficoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(GraficoContagemLaco)