import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import configLocal from '../configuracao/configLocal';

const INITIAL_VALUES = () => ({
    // controlador_id: 0,
    controlador_id: configLocal.getConfig('cont'),
    status: 1,
    numero_intervalo: 4,
    modo_operacao_id: 0,
    imageLog: ''
});

export function getList() {

    var numCont = configLocal.getConfig('numCont');
    var grupo = configLocal.getConfig('grupo');
    const request = axiosInstance.get(`/planos/controlador/numero/${numCont}/${grupo}`)

    return {
        type: 'PLANOCONTROLADOR_FETCHED',
        payload: request
    }
}

export function getListCodigo() {

    var contId = configLocal.getConfig('cont');
    const request = axiosInstance.get(`/planos/codigo/${contId}`)

    return {
        type: 'PLANOCODIGO_FETCHED',
        payload: request
    }
}

export function getControladorList() {
    const request = axiosInstance.get(`/controladores`)

    return {
        type: 'CONTROLADOR_FETCHED',
        payload: request
    }
}

export function getModoOperacaoList() {
    const request = axiosInstance.get(`/planos/modoop`)

    return {
        // tem que criar um reducer para percorrer o objeto e achar o MODOOPERACAO_FETCHED com isso ele pega a propriedade "data"
        type: 'MODOOPERACAO_FETCHED',
        payload: request
    }
}

export function limpa(values) {
    var contId = configLocal.getConfig('cont');
    var planoId = values.id;

    return async dispatch => {
        await axiosInstance.put(`/planos/limpa/${contId}/${planoId}`, values)
            .then(()=>{
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init());
            })
            .catch(e => {
                console.log(e.response.data);
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    };
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {
    values['grupo_fisico_id'] = configLocal.getConfig('grupo');
    values['numero_controlador'] = configLocal.getConfig('numCont');
    values['anel'] = configLocal.getConfig('anel');

    return async dispatch => {
        const id = values.id ? values.id : '';
        const controlador_id = values.controlador_id ? values.controlador_id : '';

        await axiosInstance[method](`/planos/${controlador_id}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                if (id) {
                    dispatch(init());
                }
            })
            .catch(e => {
                console.log(e.response.data);
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showIntervalo(plano) {

    return [
        showTabs('tabIntervalo'),
        selectTab('tabIntervalo'),
        initialize('planoIntervalo', plano)
    ]
}

export function showUpdate(plano) {
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('planoForm', plano)
    ]
}

export function showDelete(plano) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('planoForm', plano)
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        getListCodigo(),// se tirar isso ele não atualiza o combo de codigo de plano quando excluir um registro
        initialize('planoForm', INITIAL_VALUES())
    ]
}
