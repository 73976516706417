import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ContentHeader from '../common/template/contentHeader'
import Content from '../common/template/content'
import Tabs from '../common/tab/tabs'
import TabsHeader from '../common/tab/tabsHeader'
import TabsContent from '../common/tab/tabsContent'
import TabHeader from '../common/tab/tabHeader'
import TabContent from '../common/tab/tabContent'
import { init, create, update } from './grupoFisicoActions'
import PermissaoUsuario from '../auth/permissao';

import List from './grupoFisicoList';
import Form from './grupoFisicoForm'

class GrupoFisico extends Component {

    componentWillMount() {
        this.props.init();
        
    }

    render() {
        return (
            <div>
                <ContentHeader title='Grupo Físico' small='Cadastro' />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Listar' icon='bars' target='tabList' />
                            <PermissaoUsuario tela="CadGrupoFisico" condicao={true}>
                                <TabHeader label='Incluir' icon='plus' target='tabCreate' />
                                <TabHeader label='Alterar' icon='pencil-alt' target='tabUpdate' />
                            </PermissaoUsuario>
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabList'>
                                <List />
                            </TabContent>
                            <TabContent id='tabCreate'>
                                <Form onSubmit={this.props.create} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                            <TabContent id='tabUpdate'>
                                <Form onSubmit={this.props.update} readOnly={false} submitClass='primary' submitLabel='Salvar' />
                            </TabContent>
                        </TabsContent>
                    </Tabs>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, create, update }, dispatch)
export default connect(null, mapDispatchToProps)(GrupoFisico)