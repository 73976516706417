import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import If from '../operator/if'
import {selectTab} from './tabActions'

class TabHeader extends Component {
    render() {
        const selected = this.props.tab.selected === this.props.target
        const visible = this.props.tab.visible[this.props.target]
        return (
            <If condicao={visible}>
                <li className={selected ? 'active' : ''}>
                    {/* <a href={`javascript:;`}
                        data-toggle='tab'
                        onClick={() => this.props.selectTab(this.props.target)}
                        data-target={this.props.target}>
                        <i className={`fa fa-${this.props.icon} ${this.props['data-icon']}`}></i> {this.props.label}
                    </a>  */}

                    <button className={'button_tabs'}
                        data-toggle='tab'
                        onClick={() => this.props.selectTab(this.props.target)}
                        data-target={this.props.target}>
                        <i className={`fa fa-${this.props.icon} ${this.props['data-icon']}`}></i> {this.props.label}
                        <a className={`fa fa-times`} 
                           style={{paddingLeft: '10px', display: this.props.closeAction ? '': 'none', color: 'black'}}
                           href="javascript: $('.fa-close').trigger('click')" hint='Fechar'>
                        </a>
                    </button>
                </li> 
            </If>
        )
    }
}

const mapStateToProps = state => ({tab : state.tab})
const mapDispatchToProps = dispatch => bindActionCreators({selectTab}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(TabHeader)