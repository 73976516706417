import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../form/labelAndSelectForm';
import configLocal from '../../configuracao/configLocal';

import { getControladorGrupoList } from './selectdbAtions';

class SelectNumeroControlador extends Component {

    componentWillMount() {
        this.props.getControladorGrupoList();
    }

    renderOptions() {
        const listNumCont = this.props.list || [];

        const grupoFisicoId = configLocal.getConfig('grupo');
        var listCont = [];
        // isso é feito redundante pq se ficar em cache o sistema busca todos os controladores sem filtro de grupo
        listNumCont.forEach(cont => {
            if (parseInt(grupoFisicoId) == parseInt(cont.grupo_fisico_id)) {
                listCont.push(cont);
            }
        });

        var bExiste = false;

        var list = [];

        // mostra 00 isolado só se não tiver nenhum controlador salva no grupo focal
        if(listNumCont.length == 0){
            list[0] = { id: 0, desc: `00` };
        }

        for (var i = 1; i <= 9; i++) {

            bExiste = false;
            listCont.forEach(cont => {
                if (i == cont.numero_controlador) {
                    bExiste = true;
                }
            });

            if (!bExiste){
                list[i] = { id: i, desc: `0${i}` };
            }
        }

        for (var i = 10; i <= 99; i++) {

            bExiste = false;
            listCont.forEach(cont => {
                if (i == cont.numero_controlador) {
                    bExiste = true;
                }
            });

            if (!bExiste){
                list[i] = { id: i, desc: i };
            }
            
        }

        return list.map(sequencia => (
            <option key={sequencia.id} value={sequencia.id}>{sequencia.desc}</option>
        ));

        
    }

    render() {
        return (
            <Field
                name={this.props.name}
                component={LabelAndSelectForm}
                label={this.props.label}
                cols={this.props.cols}
                validate={this.props.validate}
            >
                <option value='selecione'>Selecione o número do controlador</option>
                {this.renderOptions()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getControladorGrupoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectNumeroControlador)