import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { update } from './faseActions';
import Select from '../common/form/select';

import ReactTableGT2A from '../common/grid/gridReactTable';
import SPButton from '../common/form/spButton';
import Button from '../common/form/button';
import { Modal } from 'react-bootstrap';
import MapaFase from './mapaFase';
import PermissaoUsuario from '../auth/permissao';
import DivEditableGridNumeric from '../common/grid/divEditableGridNumeric';

class FaseList extends Component {

    constructor() {
        super();

        this.renderEditableAusencia = this.renderEditableAusencia.bind(this);
        this.renderEditablePiscante = this.renderEditablePiscante.bind(this);
        this.renderEditablePiscantePedestre = this.renderEditablePiscantePedestre.bind(this);
        this.renderEditableTipo = this.renderEditableTipo.bind(this);
        this.renderButtonMap = this.renderButtonMap.bind(this);
        this.renderEditableQuantidade = this.renderEditableQuantidade.bind(this);
        this.renderEditableMinimo = this.renderEditableMinimo.bind(this);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clickOk = this.clickOk.bind(this);

        this.clickMapaFase = this.clickMapaFase.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeTipo = this.onChangeTipo.bind(this);

        this.state = {
            show: false,
            html: '',
            idx: null,
            controladorTitle: '',
            fases: []
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(row, cellInfo) {

        this.setState({
            html:
                <MapaFase
                    data-contId={row.controlador_id}
                    data-faseId={row.id}
                    data-latitude={row.latitude}
                    data-longitude={row.longitude}
                    latitude={row.latitude}
                    longitude={row.longitude}
                    data-fases={this.state.fases}
                />
        });

        this.setState({ show: true });
    }

    clickOk(cellInfo) {
        this.setState({ show: false });
    }

    clickMapaFase(cellInfo) {
        const data = this.state.fases;

        this.setState({
            html: <MapaFase
                data-contId={data[cellInfo.index].controlador_id}
                data-faseId={data[cellInfo.index].id}
                data-latitude={data[cellInfo.index].latitude}
                data-longitude={data[cellInfo.index].longitude}
                data-idx={cellInfo.index}
                latitude={-27.21}
                longitude={-49.64}
                data-fases={this.state.fases}
            />
        });
        this.setState({ idx: cellInfo.index });

        this.setState({ controladorTitle: `- Controlador ${data[cellInfo.index].controlador_id} - Fase ${data[cellInfo.index].id}` })

        this.setState({ show: true });
    }

    renderButtonMap(cellInfo) {
        return (
            <span>
                <PermissaoUsuario tela="CadControlador" campo="localizacao_fase_mapa" condicao={true}>
                    <SPButton
                        type='button'
                        className='success btn-sm'
                        icon='map-marked-alt'
                        data-title='Configurar Mapa'
                        onClick={() => { this.clickMapaFase(cellInfo) }}
                    />
                </PermissaoUsuario>
                <PermissaoUsuario tela="CadControlador" campo="localizacao_fase_mapa" condicao={false}>
                    <SPButton
                        type='button'
                        className='success btn-sm'
                        icon='map-marked-alt'
                        data-title='Configurar Mapa'
                        disabled="disabled"
                    />
                </PermissaoUsuario>
            </span>
        );
    }

    componentWillMount() {
        this.setState({ fases: this.props['data-fases'] });
    }

    renderEditableAusencia(cellInfo) {
        return (
            <Select
                className='select-grid'
                contentEditable
                suppressContentEditableWarning
                onChange={(event) => { this.onChangeSelect(event, cellInfo) }}
                value={this.state.fases[cellInfo.index][cellInfo.column.id]}
                disabled={true}
            >
                <option value='1'>Sim</option>
                <option value='0'>Não</option>
            </Select>

        );
    }

    onChangeSelect(event, cellInfo) {
        const data = this.state.fases;

        if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
            data[cellInfo.index][cellInfo.column.id] = event.target.value;
            this.setState({ data });
        }
    }

    onChangeTipo(event, cellInfo) {

        const data = this.state.fases;

        if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
            data[cellInfo.index][cellInfo.column.id] = event.target.value;

            if ((parseInt(event.target.value) === 1) && (parseInt(data[cellInfo.index].quantidade) > 6)) {
                data[cellInfo.index].quantidade = 1;
                data[cellInfo.index].quantidade_lat_lon = '';
            }

            this.setState({ data });
        }
    }

    onChangeQuantidade(event, cellInfo) {
        const data = this.state.fases;
        let pedestreLatLon = [];

        if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
            let valorDigitado = parseInt(event.target.value);

            data[cellInfo.index][cellInfo.column.id] = valorDigitado;

            for (let index = 0; index < valorDigitado; index++) {
                pedestreLatLon.push(
                    {
                        latitude: data[cellInfo.index].latitude,
                        longitude: data[cellInfo.index].longitude
                    }
                )

            }
            data[cellInfo.index].quantidade_lat_lon = JSON.stringify(pedestreLatLon);

            this.setState({ data });

        }
    }

    renderEditablePiscante(cellInfo) {
        return (
            <Select
                className='select-grid'
                contentEditable
                suppressContentEditableWarning
                value={this.state.fases[cellInfo.index][cellInfo.column.id]}
                disabled={true}
            >
                <option value='5'>Amarelo</option>
                <option value='4'>Vermelho</option>
            </Select>
        );
    }

    renderEditablePiscantePedestre(cellInfo) {
        return (
            <Select
                cols='12'
                className='select-grid'
                contentEditable
                suppressContentEditableWarning
                onChange={(event) => { this.onChangeSelect(event, cellInfo) }}
                style={{ width: "100%" }}
                value={this.state.fases[cellInfo.index][cellInfo.column.id]}
                disabled={true}
            >
                <option value='5'>Apagado</option>
                <option value='4'>Vermelho</option>
            </Select>
        );
    }

    renderEditableTipo(cellInfo) {
        return (
            <Select
                className='select-grid'
                contentEditable
                suppressContentEditableWarning
                value={this.state.fases[cellInfo.index][cellInfo.column.id]}
                onChange={(event) => { this.onChangeTipo(event, cellInfo) }}
                disabled={true}
            >
                <option value='1'>Veículo</option>
                <option value='2'>Pedestre</option>
            </Select>
        );
    }

    renderEditableQuantidade(cellInfo) {
        let optionList = [];

        for (let index = 1; index <= 16; index++) {
            optionList.push(
                <option key={index} value={`${index}`}>{index}</option>
            )

            if ((index === 6) && (parseInt(this.state.fases[cellInfo.index].tipo) === 1))
                break;
        }


        return (
            <span>
                <PermissaoUsuario tela="CadControlador" campo="localizacao_fase_mapa" condicao={false}>
                    <Select
                        className='select-grid'
                        contentEditable
                        suppressContentEditableWarning
                        value={this.state.fases[cellInfo.index][cellInfo.column.id]}
                        onChange={(event) => { this.onChangeQuantidade(event, cellInfo) }}
                        disabled={true}
                    >
                        {optionList}
                    </Select>
                </PermissaoUsuario>
                <PermissaoUsuario tela="CadControlador" campo="localizacao_fase_mapa" condicao={true}>
                    <Select
                        className='select-grid'
                        contentEditable
                        suppressContentEditableWarning
                        value={this.state.fases[cellInfo.index][cellInfo.column.id]}
                        onChange={(event) => { this.onChangeQuantidade(event, cellInfo) }}
                    >
                        {optionList}
                    </Select>
                </PermissaoUsuario>
            </span>
        );
    }

    renderEditableMinimo(cellInfo) {
        let corFundo = '#f45555';
        switch (cellInfo.column.id) {
            case 'tempo_amarelo_min':
                corFundo = '#FFEB3B';
                break;
            case 'tempo_verde_min':
                corFundo = '#68BC00';
                break;
            default:
                corFundo = '#f45555';
                break;
        }
        
        return (
            <DivEditableGridNumeric
                contentEditable={false}
                inputMode={'numeric'}
                style={{ backgroundColor: corFundo }}
                dataType={'integer'}
                suppressContentEditableWarning
                id={`${cellInfo.column.id}_${cellInfo.index}`}
                value={this.state.fases[cellInfo.index][cellInfo.column.id]}
            />
        );
    }

    render() {
        const fase = this.state.fases || [];

        if (fase.length > 1) {

            const columns = [

                {
                    Header: 'Ações',
                    Cell: this.renderButtonMap,
                    className: 'column_icon',
                    width: 50,
                    Filter: () => <div className='rt-th'><span >Filtrar</span></div>
                },
                {
                    Header: 'Fase',
                    accessor: 'id',
                    className: 'column_grid column_center',
                    width: 60,
                    Cell: ({ value }) => (`Fase ${value}`)
                },
                {
                    Header: 'Monitora Vermelho',
                    accessor: 'ausencia_vermelho',
                    className: 'column_grid column_select',
                    Cell: this.renderEditableAusencia,
                    width: 130
                },
                {
                    Header: 'Cor Piscante',
                    accessor: 'cor_piscante',
                    className: 'column_grid column_select',
                    Cell: this.renderEditablePiscante,
                    width: 130
                },
                {
                    Header: 'Latitude',
                    accessor: 'latitude',
                    className: 'column_grid column_center',
                    width: 90
                },
                {
                    Header: 'Logitude',
                    accessor: 'longitude',
                    className: 'column_grid column_center',
                    width: 90
                },
                {
                    Header: 'Tipo',
                    accessor: 'tipo',
                    className: 'column_grid column_select',
                    Cell: this.renderEditableTipo,
                    width: 130
                },
                {
                    Header: 'Quantidade',
                    accessor: 'quantidade',
                    className: 'column_grid column_select',
                    Cell: this.renderEditableQuantidade,
                    width: 90
                },

                {
                    Header: 'Tempo mínimo',
                    accessor: 'tempo_vermelho_min',
                    className: 'column_grid column_center',
                    Cell: this.renderEditableMinimo,
                    width: 100
                },
                {
                    Header: 'Tempo mínimo',
                    accessor: 'tempo_amarelo_min',
                    className: 'column_grid column_center',
                    Cell: this.renderEditableMinimo,
                    width: 100
                },
                {
                    Header: 'Tempo mínimo',
                    accessor: 'tempo_verde_min',
                    className: 'column_grid column_center',
                    Cell: this.renderEditableMinimo,
                    width: 100
                },
                {
                    Header: '',
                    accessor: 'vazio',
                    className: 'column_grid'
                }
            ];

            return (
                <div>
                    <ReactTableGT2A
                        data={fase}
                        columns={columns}
                        defaultPageSize={fase.length}
                        showPaginationBottom={true}
                    />

                    <Modal show={this.state.show} onHide={this.handleClose} dialogClassName='modal-mapa'>
                        <Modal.Header className='modal-header' closeButton>
                            <Modal.Title>Mapa de Fases {this.state.controladorTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='modal-body'>

                            {this.state.html}

                        </Modal.Body>
                        <Modal.Footer className='modal-header'>
                            <Button type='button' className='primary' icon='check' label='OK' onClick={this.clickOk} />
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        }

        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ update }, dispatch)
export default connect(null, mapDispatchToProps)(FaseList)
