function getData(){

    var _cfg = localStorage.getItem('_ssat');

    if (_cfg != null) {
        _cfg = JSON.parse(_cfg);

        return _cfg['data'];
    }

    return '0';

}

module.exports = { getData }