import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { getList, getRemoteList } from './erroActions';
import ButtonTooltip from '../common/form/buttonTooltip';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { parseISO, format, isValid } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import ReactTableGT2A from '../common/grid/gridReactTable';

moment.locale('pt-br');

class ControladorErroList extends Component {

    constructor(props) {
        super(props);

        this.intervalGetList = 0;
        this.carregarErro = this.carregarErro.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);

        this.state = {
            show: false
        };

    }

    componentWillUnmount() {
        clearInterval(this.intervalGetList);
    }

    componentDidMount() {
        this.intervalGetList = setInterval(() => {
            this.props.getList();
        }, 5000);
    }

    componentWillMount() {
        this.props.getList();
    }

    handleClose() {
        this.setState({ show: false });
    }

    timeFormat(time) {
        let timeFormatted = time.replace(/\D/g, '');
        let hour = timeFormatted.substring(0, 2);
        let minute = timeFormatted.substring(2, 4);
        let second = timeFormatted.substring(4, 6);
        hour = hour > 23 ? '23' : hour;
        minute = minute > 59 ? '59' : minute;
        second = second > 59 ? '59' : second;
        return `${hour}${minute ? ':' + minute : ''}${second ? ':' + second : ''}`;
    }

    handleChangeTime(value, onChange) {
        const time = this.timeFormat(value);
        onChange(time);
    }

    carregarErro() {
        this.setState({ show: true });
        this.props.getRemoteList()
            .then((result) => {
                this.props.getList();
                this.setState({ show: false });
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', 'Sem conexão');
            });

    }

    render() {

        moment.locale('pt-br');        

        const contErro = this.props.list || [];

        const columns = [
            {
                Header: 'Data',
                accessor: 'data_erro',
                width: 150,
                filterable: true,
                className: 'column_grid column_center',
                Cell: ({ value }) => (moment(value).format('DD/MM/YYYY')),
                Filter: ({ filter, onChange }) => (
                    <input
                        type='date'
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ''}
                    />
                ),
                filterMethod: (filter, row) => {
                    if (isValid(parseISO(row[filter.id]))) {
                        const dataValue = format(parseISO(row[filter.id]), 'yyyy-MM-dd');
                        return dataValue === filter.value;
                    }
                }
            },
            {
                Header: 'Hora',
                accessor: 'hora_erro',
                width: 120,
                className: 'column_grid column_center',
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input  
                        onChange={event => this.handleChangeTime(event.target.value, onChange)}
                        value={filter ? filter.value : ''}
                        type='text'
                        maxLength={8}
                        placeholder='hh:mm:ss'
                        className='form-control'
                    />
                )
            },
            {
                Header: 'Mensagem',
                accessor: 'mensagem',
                className: 'column_grid',
                filterable: true
            }
        ];

        return (

            <div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false}
                >

                    <Modal.Body className='modal-body modal-load-body'>

                        <div >

                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>

                        </div>


                    </Modal.Body>

                </Modal>

                <ReactTableGT2A
                    data={contErro}
                    columns={columns}
                    className='-striped -highlight'
                    defaultPageSize={10}
                />

                <div className='box-footer'>
                    <ButtonTooltip
                        type='button'
                        className='success'
                        icon='download'
                        label='Carregar Erros Controlador'
                        data-title='Busca erros do controlador para gravar no banco e exibe'
                        onClick={this.carregarErro}
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({ list: state.controladorErroCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getList, getRemoteList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ControladorErroList)
