const confKey = '_cfg';

function getConfig(campo){
    return getAllConfig()[campo];
}

function getAllConfig() {
    var _cfg = localStorage.getItem(confKey);

    if (_cfg != null) {
        return JSON.parse(_cfg);
    }

    return loadConfig();
}

function loadConfig() {
    let conf = {
        cont: 0,
        numCont: 0,
        grupo: 0,
        anel: 0,
        contLat: '',
        contLong: '',
        disabledbtnplano: false
    };
    
    localStorage.setItem(confKey, JSON.stringify(conf));
    return conf;
}

function setConfig(campo, valor) {
    let conf = getAllConfig();
    conf[campo] = valor;
    localStorage.setItem(confKey, JSON.stringify(conf));
}


module.exports = { getConfig, setConfig }