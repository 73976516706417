import React, { Component } from 'react';
import './custom.css'

class MenuTree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

    }

    renderOptions() {

    }

    render() {
        return (

            <li className='treeview' >
                <a className='menu_item' href={this.props.path}>
                    <i className={`fa fa-${this.props.icon}`}></i> <span>{this.props.label}</span>
                    <span className="pull-right-container">
                        <i className="fa fa-caret-down pull-right"></i>
                    </span>
                </a>
                <ul className='treeview-menu' style={{display: 'none'}} >
                    {this.props.children}
                </ul>
            </li>
        )
    }
}

export default MenuTree;