import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import GraficoErroGeral from './graficoErroGeral';
import { getErrosList } from './erroGeralActions';
import LabelAndInputChange from '../common/form/labelAndInputChange';
import { Panel, Modal } from 'react-bootstrap';
import Button from '../common/form/button';
import configLocal from '../configuracao/configLocal';
import SelectAllControladorNumero from '../common/selectdb/selectAllControladorNumero';

moment.locale('pt-br');

class ControladorErroGeralGraph extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            showFiltro: false,
            listErros: [],
            filtroAux: {
                controlador: null,
                dataInicial: moment().format('YYYY-MM-DD'),
                dataFinal: moment().format('YYYY-MM-DD')
            },
            filtro: {
                controlador: null,
                dataInicial: moment().format('YYYY-MM-DD'),
                dataFinal: moment().format('YYYY-MM-DD')
            }
        };

        this.aplicarFiltro = this.aplicarFiltro.bind(this);
        this.handleCloseFiltro = this.handleCloseFiltro.bind(this);
        this.handleShowFiltro = this.handleShowFiltro.bind(this);
        this.onChangeDataInicial = this.onChangeDataInicial.bind(this);
        this.onChangeDataFinal = this.onChangeDataFinal.bind(this);
        this.onChangeControlador = this.onChangeControlador.bind(this);
        this.limparFiltro = this.limparFiltro.bind(this);
    }

    aplicarFiltro() {
        let filtroTela = this.state.filtroAux;

        let filtroNovo = this.state.filtro;
        filtroNovo.controlador = filtroTela.controlador;
        filtroNovo.dataInicial = filtroTela.dataInicial;
        filtroNovo.dataFinal = filtroTela.dataFinal;

        this.handleCloseFiltro();

        this.props.getErrosList(this.state.filtro)
            .then((result) => {
                this.setState({
                    listErros: result.payload.data
                });
            });
    }

    componentWillMount() {
        this.props.getErrosList(this.state.filtro);
    }

    handleCloseFiltro() {
        this.setState({
            showFiltro: false
        });
    }

    handleShowFiltro() {
        this.setState({
            showFiltro: true
        });
    }

    onChangeDataInicial(event) {
        let filtroAux = this.state.filtroAux;
        filtroAux.dataInicial = event.target.value;
        this.setState({ filtroAux: filtroAux });
    }

    onChangeDataFinal(event) {
        let filtroAux = this.state.filtroAux;
        filtroAux.dataFinal = event.target.value;
        this.setState({ filtroAux: filtroAux });
    }

    onChangeControlador(event) {
        let filtroAux = this.state.filtroAux;
        filtroAux.controlador = null;
        if (!isNaN(event.target.value)) {
            filtroAux.controlador = event.target.value;
        }
        this.setState({ filtroAux: filtroAux });
    }

    limparFiltro() {
        let filtroNovo = this.state.filtro;
        filtroNovo.controlador = null;
        filtroNovo.dataInicial = moment().format('YYYY-MM-DD');
        filtroNovo.dataFinal = moment().format('YYYY-MM-DD');
        this.setState({ filtro: filtroNovo });

        let filtroNovoAux = this.state.filtroAux;
        filtroNovoAux.controlador = null;
        filtroNovoAux.dataInicial = moment().format('YYYY-MM-DD');
        filtroNovoAux.dataFinal = moment().format('YYYY-MM-DD');
        this.setState({ filtroAux: filtroNovoAux });

        this.props.getErrosList(this.state.filtro)
            .then((result) => {
                this.setState({
                    listErros: result.payload.data
                });
            });
    }

    componentDidMount() {
        this.props.getErrosList(this.state.filtro)
            .then((result) => {
                this.setState({
                    listErros: result.payload.data
                });
            });
    }

    render() {
        let grafico;

        grafico = <GraficoErroGeral
            listErros={this.state.listErros}
            filtrosGrafico={this.state.filtro} />;

        return (

            <Panel bsStyle="success">
                <div>
                    <Modal show={this.state.showFiltro} onHide={this.handleCloseFiltro} dialogClassName='modal-dialog'>
                        <Modal.Header className='modal-header' closeButton>
                            <Modal.Title>Filtros dos Gráficos</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className='modal-body'>

                            <SelectAllControladorNumero
                                label='Controlador'
                                name='controladorGrafico' 
                                id='controladorGrafico'
                                data-grupofisico-id={configLocal.getConfig('grupo')}
                                value={this.state.filtroAux.controlador}
                                onChange={this.onChangeControlador} />

                            <LabelAndInputChange
                                name='data_inicial'
                                className='modal-campo-data'
                                type='date'
                                label='Data Término Inicial'
                                cols='12'
                                value={this.state.filtroAux.dataInicial}
                                onChange={this.onChangeDataInicial} />

                            <LabelAndInputChange
                                name='data_final'
                                className='modal-campo-data'
                                type='date'
                                label='Data Término Final'
                                cols='12'
                                value={this.state.filtroAux.dataFinal}
                                onChange={this.onChangeDataFinal} />
                        </Modal.Body>

                        <Modal.Footer className='modal-header'>
                            <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.aplicarFiltro} />
                        </Modal.Footer>
                    </Modal>
                </div>
                
                <Panel.Heading className='panel_heading'>
                    <Panel.Title componentClass="h3">
                        <Button type='button' className='primary btn-sm pull-right btn_label_size' icon='filter' label='Filtro Gráfico' onClick={this.handleShowFiltro} />
                        <Button type='button' className='success btn-sm pull-right btn_label_size' icon='eraser' label='Limpar Filtro' onClick={this.limparFiltro} />
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <div height={500} >
                        {grafico}
                    </div>
                </Panel.Body>
            </Panel>
        )
    }
}

const mapStateToProps = state => ({ listErros: state.controladorErroGeralCad.listErros })
const mapDispatchToProps = dispatch => bindActionCreators({ getErrosList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ControladorErroGeralGraph)
