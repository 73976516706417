const INITIAL_STATE = { list: [], listCod: [] }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'DETECTOR_FETCHED':
            return { ...state, list: action.payload.data }
        case 'DETECTORCOD_FETCHED':
            return { ...state, listCod: action.payload.data }
        default:
            return state
    }
}
