import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import configLocal from '../configuracao/configLocal';

// const INITIAL_VALUES = {}

export function getList(contid) {

    const request = axiosInstance.get(`/fases/${contid}`);

    return {
        type: 'FASE_FETCHED',
        payload: request 
    }
}

export function getControladorFase() {
    const request = axiosInstance.get(`/fases/mapa/geral`)

    return {
        type: `FASE_FETCHED`,
        payload: request
    }
}

export function getControladorCores() {
    const request = axiosInstance.get(`/fasesgeral/mapa`);

    return {
        type: `FASEMAP_FETCHED`,
        payload: request
    }   
}

export function getControladorCoresGT(contId) {
    const request = axiosInstance.get(`/fasesgeral/mapagt/${contId}`);

    return {
        type: `FASEMAP_FETCHED`,
        payload: request
    }   
}

export function getControladorCoresGF(grupoId) {
    const request = axiosInstance.get(`/fasesgeral/mapagf/${+grupoId}`);

    return {
        type: `FASEMAP_FETCHED`,
        payload: request
    }   
}

export function getControladorList() {
    let grupoFisicoId = configLocal.getConfig('grupo');
    const request = axiosInstance.get(`/fasesgeral/mapagt/controlador/${grupoFisicoId}`);

    return {
        type: `FASEMAPCONTROLADORLIST_FETCHED`,
        payload: request
    }   
}

export function getInicioMapa(grupo) {
    const request = axiosInstance.get(`/fasesgeral/mapa/inicio/${+grupo}`);

    return {
        type: `FASEMAPAINICIO_FETCHED`,
        payload: request
    }   
}

export function update(values) {
    return submit(values, 'put')
}

function submit(values, method) {

    return dispatch => {
        const id = values.id ? values.id : '';
        const controlador_id = values.controlador_id;
        
        axiosInstance[method](`/fases/${controlador_id}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function init() {
    return [
        getList()
    ]
}
