const INITIAL_STATE = { list: [], listPlano: [] }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADAPTATIVO_FETCHED':
            return { ...state, list: action.payload.data }
        case 'ADAPTATIVOPLANO_FETCHED':
            return { ...state, listPlano: action.payload.data }
        default:
            return state
    }
}
