import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='checkbox'>
            <label htmlFor={props.name}> 
            <input {...props.input}
                readOnly={props.readOnly}
                type={props.type}
                id={props.id}
                className={props.className}
                disabled={props.disabled}
                // key={props.key}
                // value={props.value}
                // checked={props.checked}
                // onChange={props.onChange}
            />
            {/* <input {...props.input} 
                readOnly={props.readOnly} 
                type={props.type} 
                id={props.id}
                name={props.name}
               // checked={props.checked}
                
                onChange={props.onChange}
                //defaultValue={props.defaultValue}
            />  */}

            {/* <input type="checkbox" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} /> */}
                
                {props.label}
                
            </label>
        </div>
       
    </Grid>
)