import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import { getListCodigo } from './detectorActions';

class SelectIDDetector extends Component {

    componentWillMount() {
        this.props.getListCodigo();
    }

    renderRows() {

        const list = this.props.listCod || [];

        return list.map(detec => (
            <option key={detec.id} value={detec.id}>{detec.desc}</option>
        ))
    }

    render() {

        return (
            <Field name="id" component={LabelAndSelectForm} label='Código'
            >
                <option value="Selecione">Selecione</option>
                {this.renderRows()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ listCod: state.detectorCad.listCod })
const mapDispatchToProps = dispatch => bindActionCreators({ getListCodigo }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectIDDetector)