import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';

const INITIAL_VALUES = {}

export function getList() {
    const request = axiosInstance.get(`/usuarios`)
    
    return {
        type: 'USUARIO_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {    
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {    
    
    return dispatch => {
        const id = values.id ? values.id : '';
        
        // se for um delete tem que adicionar como data para aparecer no body do backend e gravar o log
        if (method === 'delete'){
            values = {data: values };
        }

        axiosInstance[method](`/usuarios/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showUpdate(usuario) {
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('usuarioForm', usuario)
    ]
}

export function showDelete(usuario) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('usuarioForm', usuario)
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        { type: 'NOTIFICACAO_USUARIO_CLEAR' },
        initialize('usuarioForm', INITIAL_VALUES)
    ]
}