import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';

const INITIAL_VALUES = {}

export function getList() {

    var contId = configLocal.getConfig('cont');

    const request = axiosInstance.get(`/detectores/${contId}`)

    return {
        type: 'DETECTOR_FETCHED',
        payload: request
    }
}

export function getListCodigo() {

    var contId = configLocal.getConfig('cont');

    const request = axiosInstance.get(`/detectores/codigo/${contId}`)
    
    return {
        type: 'DETECTORCOD_FETCHED',
        payload: request
    }
}

export function create(values) {
    values.controlador_id = configLocal.getConfig('cont');
    return submit(values, 'post');
}

export function update(values) {
    
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {

    return dispatch => {
        const id = method != 'post' ? values.id : ''
        const contId = values.controlador_id;
        axiosInstance[method](`/detectores/${contId}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showUpdate(detector) {

    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('detectorForm', detector)
    ]
}

export function showDelete(detector) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('detectorForm', detector)
    ]
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        getListCodigo(),// tem que colocar aqui para atualizar quando salva um novo
        initialize('detectorForm', INITIAL_VALUES)
    ]
}
