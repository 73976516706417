import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../form/labelAndSelectForm';

import { getModoOperacaoList } from './selectdbAtions';

class SelectModoOperacao extends Component {

    componentWillMount() {
        this.props.getModoOperacaoList();
    }

    renderOptions() {
        const list = this.props.list || [];
                     
        return list.map(modo => (
            <option key={modo.id} value={modo.id}>{modo.descricao}</option>
        ))
    }

    render() {
        this.renderOptions();
        return (
            <Field 
                name={this.props.name} 
                component={LabelAndSelectForm} 
                label={this.props.label} 
                cols={this.props.cols} 
                disabled={this.props.disabled} >
                {this.renderOptions()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ list: state.modoOperacaoCon.list });
const mapDispatchToProps = dispatch => bindActionCreators({ getModoOperacaoList }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SelectModoOperacao);