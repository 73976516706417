import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { init } from './tipoAlarmeActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';

class TipoAlarmeForm extends Component {


    render() {

        const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (            
            <div>
            <h1>{touched}</h1>
              <label>{label}</label>
              <div>
                <input {...input} placeholder={label} type={type}/>
                {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
              </div>
            </div>
          )

        const required = value => value ? undefined : 'Required';

        const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
        const minLength2 = minLength(2);

        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit, readOnly } = this.props
        //const { sumOfCredits, sumOfDebts } = this.calculateSummary()
        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>

                    <Field name='id' component={LabelAndInput} readOnly={true}
                        label='Código' cols='12' placeholder='Automático' />

                    <Field
                        name='descricao'
                        type='text'
                        component={LabelAndInput}
                        label='Descrição' 
                        cols='12' 
                        placeholder='Informe a descrição' 
                        validate={[required, minLength2]}                    
                    />

                </div>
                <div className='box-footer'>
                    <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um 
// form limpo para o update
TipoAlarmeForm = reduxForm({ form: 'tipoAlarmeForm', destroyOnUnmount: false })(TipoAlarmeForm);

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(null, mapDispatchToProps)(TipoAlarmeForm);