import React, { Component } from 'react';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';

class SelectDia extends Component {

    renderRows() {

        var list = [];
        const mes_selecionado = parseInt(this.props['data-mes']);

        if (parseInt(mes_selecionado) === 2) {
            for (var i = 1; i <= 29; i++) {
                list[i] = { id: i, desc: i };
            }
        } else {
            if ([1, 3, 5, 7, 8, 10, 12].indexOf(mes_selecionado) >= 0){
                for (let i = 1; i <= 31; i++) {
                    list[i] = { id: i, desc: i };
                }
            }else{
                for (let i = 1; i <= 30; i++) {
                    list[i] = { id: i, desc: i };
                }
            }        
        }        
        // 2 4 6   9 11

        return list.map(dia => (
            <option key={dia.id} value={dia.id}>{dia.desc}</option>
        ))
    }

    render() {

        return (

            <Field name="dia" component={LabelAndSelectForm} label='Dia' >
                {this.renderRows()}
            </Field>
        )
    }
}

export default SelectDia;