import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { getListCodigo, init } from './planoActions';
import LabelAndInput from '../common/form/labelAndInput';

import Button from '../common/form/button';

import SelectCodigo from './selectPlanoCodigo';
import If from '../common/operator/if';
import SelectIntervalo from './selectIntervalo';
import SelectModoOperacao from '../common/selectdb/selectModoOperacao';
import SelectAnelControlador from '../common/selectdb/selectAnelControlador';
import swal from 'sweetalert2';
import { validaPermissaoDisabled, validaPermissaoReadOnly } from '../auth/permissaoActions';
import configLocal from '../configuracao/configLocal';
import html2canvas from 'html2canvas';

class PlanoForm extends Component {

    constructor(props) {
        super(props);

        this.componentRef = React.createRef();

        this.salvarPlano = this.salvarPlano.bind(this);

        this.state = {
            intervalo: 0,
            update: true,
            descricao: ''
        }
    }

    componentWillMount() {
        this.setState({ intervalo: this.props.valores.numero_intervalo });
        this.setState({ update: this.props.valores.id != null });// utilizado para saber se é um insert ou update
        this.setState({ descricao: this.props.valores.descricao });
    }

    async salvarPlano() {
        if (!this.props.valid) {
            swal({
                type: 'warning',
                title: 'Informe os campos obrigatórios'
            });
            return;
        }

        await html2canvas(this.componentRef.current)
            .then((canvas) => {
                canvas.style.display = 'none';
                let image = canvas.toDataURL("png");
                this.props.change('imageLog', image);
            });

        if ((this.state.update) && (parseInt(this.props.valores.numero_intervalo) !== this.state.intervalo)) {
            swal({
                title: 'O "Intervalo Luminoso" foi alterado, isso vai limpar a configuração das fases no intervalo, colocando todas como vermelho. Deseja continua?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: 'primary',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: 'false'
            }).then((result) => {
                if (result.value) {
                    this.props.handleSubmit();
                }
            });

        } else {
            this.props.handleSubmit();
        }
    }

    render() {
        const required = value => value ? undefined : 'Obrigatório';

        const requiredSelect = value => (((value === 'selecione') || (value == '') || (value == undefined)) ? 'Obrigatório' : undefined);

        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit, readOnly } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className='box-body' ref={this.componentRef}>

                    <If condicao={!readOnly}>
                        <SelectAnelControlador
                            name='controlador_id'
                            label='Anel'
                            data-grupofisico={configLocal.getConfig('grupo')}
                            data-num-controlador={configLocal.getConfig('numCont')}
                            onChange={(e) => {
                                configLocal.setConfig('cont', e.target.value);
                                configLocal.setConfig('anel', e.target.selectedOptions[0].getAttribute('data-anel'));
                                this.props.change('controlador_id', e.target.value);
                                this.props.getListCodigo();
                            }}
                            value={this.props.valores.controlador_id}
                            cols='12'
                        />
                    </If>

                    <If condicao={readOnly}>
                        <Field name='anel' component={LabelAndInput} type='number'
                            label='Anel' cols='12' readOnly={true} />
                    </If>

                    <If condicao={!readOnly}>
                        <SelectCodigo validate={requiredSelect} />
                    </If>

                    <If condicao={readOnly}>
                        <Field name='id' component={LabelAndInput} type='number'
                            label='Código Plano' cols='12' readOnly={true} />
                    </If>

                    <Field
                        name='descricao'
                        component={LabelAndInput}
                        label='Descrição*'
                        cols='12'
                        placeholder='Informe a descrição'
                        validate={required}
                        readOnly={validaPermissaoReadOnly('CadPlano', 'descricao')}
                    />

                    <SelectModoOperacao
                        name='modo_operacao_id'
                        label='Modo Operação'
                        cols='12'
                        disabled={validaPermissaoDisabled('CadPlano', 'modo_operacao')}
                    />

                    <SelectIntervalo
                        name='numero_intervalo'
                        label='Intervalo Luminoso'
                        cols='12'
                        disabled={validaPermissaoDisabled('CadPlano', 'intervalo_luminoso')}
                    />


                </div>
                <div className='box-footer'>
                    <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.salvarPlano} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um
// form limpo para o update
PlanoForm = reduxForm({ form: 'planoForm', destroyOnUnmount: false })(PlanoForm);

const mapStateToProps = state => ({
    valores: getFormValues('planoForm')(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({ init, getListCodigo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PlanoForm);