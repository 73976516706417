import React from 'react';

export default props => (
    <button {...props}
        type={props.type}
        className={`btn btn-${props.className}`}
    >
        <i className={`fa fa-${props.icon}`}></i> <span>{props.label}</span>
    </button >

)
