import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LabelAndSelectChange from '../form/labelAndSelectChange';

import { getControladorList } from './selectdbAtions';

class SelectHeaderControlador extends Component {

    constructor(props) {
        super(props);

        this.props.getControladorList();

        this.carregaLatLon = this.carregaLatLon.bind(this);      
        
        this.state = {           
            value: this.props.value            
        }
    }

    // componentWillMount() {
    //     this.props.getControladorList();
    // }

    carregaLatLon(event){
        const list = this.props.list || [];
        let listLatLonCont = [];
        let listAnelCont = [];
        let idxSelect = event.nativeEvent.target.selectedIndex-1;

        list.forEach(cont => {
            if (this.props['data-grupofisico-id'] == cont.grupo_fisico_id) {
                listLatLonCont.push(`${cont.latitude}|${cont.longitude}`);
                listAnelCont.push(cont.anel);
            }
        });

                    
        this.props.onChange(event,listLatLonCont[idxSelect], listAnelCont[idxSelect]);
    }

    renderOptions() {
        const list = this.props.list || [];
        var listCont = [];

        list.forEach(cont => {
            if ((parseInt(this.props['data-grupofisico-id']) === parseInt(cont.grupo_fisico_id)) && (cont.ativo === 1)) {
                listCont.push(cont);
            }
        });

        return listCont.map(contro => (
            <option key={contro.id} value={contro.id}>{contro.numero_controlador} - {contro.endereco} </option>
        ))
    }

    render() {
        
        return (

            <LabelAndSelectChange
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                cols={this.props.cols}
                onChange={(event)=>{                    
                    this.setState({value: event.target.value });
                    this.carregaLatLon(event);
                }}
                value={this.state.value}
            >
                <option value="Selecionar">Selecionar</option>
                {this.renderOptions()}
            </LabelAndSelectChange>

        )
    }
}

const mapStateToProps = state => ({ list: state.controladorCad.listHeader })
const mapDispatchToProps = dispatch => bindActionCreators({ getControladorList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectHeaderControlador)