const INITIAL_STATE = { list: [], listMap: [], listCont: [] }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FASE_FETCHED':
            return { ...state, list: action.payload.data }
        case 'FASEMAP_FETCHED':
            return { ...state, listMap: action.payload.data }
        case 'FASEMAPCONTROLADORLIST_FETCHED' :
            return { ...state, listCont: action.payload.data }
        case 'FASEMAPAINICIO_FETCHED' :
            return { ...state, inicioMapa: action.payload.data }
        default:
            return state
    }
}