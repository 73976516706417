import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getPlanoList } from './selectdbAtions';

import Select from '../form/select';

class SelectPlano extends Component {

    componentWillMount() {
        this.props.getPlanoList();
    }

    renderOptions() {
        const list = this.props.list || [];
        var listPlano = [];

        list.forEach(plano => {
            if (this.props['data-controlador-id'] == plano.controlador_id) {
                listPlano.push(plano);
            }
        });

        return listPlano.map(plano => (
            <option key={plano.id} value={plano.id}>{plano.descricao}</option>
        ))
    }

    render() {

        return (
            <Select
                cols='12'
                className={this.props.className}
                contentEditable
                suppressContentEditableWarning
                onChange={this.props.onChange}
                // onChange={event => {

                //     const data = [...this.props.list];

                //     if (data[cellInfo.index][cellInfo.column.id] != event.target.value) {
                //         data[cellInfo.index][cellInfo.column.id] = event.target.value;
                //         this.setState({ data });

                //         this.props.update(data[cellInfo.index]);
                //     }
                // }}

                value={this.props.value}

            >
                <option value='0'>Nenhum</option>
                {this.renderOptions()}
            </Select>
        );
    }
}

const mapStateToProps = state => ({ list: state.planoCad.list })
const mapDispatchToProps = dispatch => bindActionCreators({ getPlanoList }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlano)