import axiosInstance from '../main/axios';
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'

const INITIAL_VALUES = {}

export function getList() {
    const request = axiosInstance.get(`/grupos`);
    
    return {
        type: 'GRUPO_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {

    return submit(values, 'put');
}

export function remove(values) {
    return dispatch => {
        const id = values.id ? values.id : '';

        axiosInstance.delete(`/grupos/${id}`, {data: values })
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

function submit(values, method) {

    const id = values.id ? values.id : ''
    // tem que ser assim o submit para poder utilizar o ".then" no grupoForm
    // depois tem que refazer o dele pois ele só atualiza o grid removendo o item excluido se utilizar o dispatch
    const request = axiosInstance[method](`/grupos/${id}`, values)
        .then(resp => {
            toastr.success('Sucesso', 'Operação Realizada com sucesso.');
            init();
            getList();
        })
        .catch(e => {
            e.response.data.errors.forEach(error => toastr.error('Erro', error))
        })

    return {
        type: '',
        payload: request
    }
}

export function showUpdate(grupo) {

    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('grupoForm', grupo)
    ]
}

export function showDelete(grupo) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('grupoForm', grupo)
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('grupoForm', INITIAL_VALUES)
    ]
}
