import React, { Component } from 'react';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';

class SelectMes extends Component {

    renderRows() {
        const list = [
            { id: 1, desc: 'Janeiro' },
            { id: 2, desc: 'Fevereiro' },
            { id: 3, desc: 'Março' },
            { id: 4, desc: 'Abril' },
            { id: 5, desc: 'Maio' },
            { id: 6, desc: 'Junho' },
            { id: 7, desc: 'Julho' },
            { id: 8, desc: 'Agosto' },
            { id: 9, desc: 'Setembro' },
            { id: 10, desc: 'Outubro' },
            { id: 11, desc: 'Novembro' },
            { id: 12, desc: 'Dezembro' }
        ];

        return list.map(mes => (
            <option key={mes.id} value={mes.id}>{mes.desc}</option>
        ))
    }

    render() {

        return (
            // <select onChange={this.handleChange}>
            //     <option value="grapefruit">Grapefruit</option>
            //     <option value="lime">Lime</option>
            //     <option value="coconut">Coconut</option>
            //     <option value="mango">Mango</option>
            // </select>


            <Field name="mes" component={LabelAndSelectForm} label='Mês'

                // props={{
                //     onChange: this.handleChange
                // }}
            >
                {this.renderRows()}
            </Field>
        )
    }
}

export default SelectMes;