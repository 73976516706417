import React from 'react';
import { withGoogleMap, GoogleMap, TrafficLayer } from "react-google-maps";


const MapWithAMarker = withGoogleMap(props =>
    <GoogleMap
        defaultZoom={15}
        defaultCenter={props.defaultCenter}
        options={{ styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }], }}
    >
        {props.displayTraffic && (
            <TrafficLayer autoUpdate />
        )}
        {props.children}
    </GoogleMap>
);

export default MapWithAMarker;

// qualquer cor
//http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FE7569

// 2 padrões do google
//https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2
//https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1

// imagens fixas
// var imageBlue = 'https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png';
// var imageRed = 'https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png';
// var imageGreen = 'https://maps.gstatic.com/mapfiles/ms2/micons/green-dot.png';
// var imageLtBlue = 'https://maps.gstatic.com/mapfiles/ms2/micons/ltblue-dot.png';
// var imageYellow = 'https://maps.gstatic.com/mapfiles/ms2/micons/yellow-dot.png';
// var imagePurple = 'https://maps.gstatic.com/mapfiles/ms2/micons/purple-dot.png';
// var imagePink = 'https://maps.gstatic.com/mapfiles/ms2/micons/pink-dot.png';