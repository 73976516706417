import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import { getList, update } from './intervaloActions';

import List from './intervaloList';

class IntervaloPlano extends Component {

    render() {
        return (
            <div>
                <ContentHeader title='Configuração de Plano' small='Cadastro' />
                <Content>

                    
                    <List />

                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getList, update }, dispatch)
export default connect(null, mapDispatchToProps)(IntervaloPlano)