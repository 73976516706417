import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import { getListCodigo } from './planoActions';

class SelectIPlanoNovo extends Component {

    componentWillMount() {
        this.props.getListCodigo();
    }

    renderRows() {

        const list = this.props.listCod || [];

        return list.map(plano => (
            <option key={plano.id} value={plano.id}>{plano.desc}</option>
        ))
    }

    render() {

        return (
            <Field
                name="id" 
                component={LabelAndSelectForm} 
                label='Código Plano*'
                validate={this.props.validate}
            >
                <option value="selecione">Selecione</option>
                {this.renderRows()}
            </Field>
        )
    }
}

const mapStateToProps = state => ({ listCod: state.planoCad.listCod })
const mapDispatchToProps = dispatch => bindActionCreators({ getListCodigo }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(SelectIPlanoNovo)