import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getErrosList } from './erroGeralActions';
import { Bar } from 'react-chartjs-2';


var optionsGrafico = {
    responsive: true,
    /* maintainAspectRatio: true, */
    scales: {
        yAxes: [{
            ticks: {
                suggestedMin: 0
            },
            stacked: false
        }],
        xAxes: {
            stacked: false
        }
    },
    /* tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        titleFontStyle: "normal",
        titleFontSize: 12,
        bodyFontSize: 12
    }, */
    legend: {
        display: true,
        position: 'bottom',
        align: 'start'
    },
    title: {
        display: true
    }
}

var corGrafico = {
    cores: [],

    getCor: (indice) => {
        if (indice == corGrafico.cores.length) {
            let cor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            corGrafico.cores.push(cor);
        }
        return corGrafico.cores[indice];
    }
}

class GraficoErroGeral extends Component {

    constructor(props) {
        super(props);

        this.dadosErroGeral = this.dadosErroGeral.bind(this);

        this.state = {
            listErros: []
        }
    }

    dadosErroGeral() {
        const erros = this.props.listErros || [];
        let dataset = [];
        let resultData = {};
        let resultErro = [];
        let dataLabels = [];
        let series = [];

        dataLabels = [];
        resultErro = [];
        for (const key in erros) {
            let serie = erros[key].mensagem,
                chave = erros[key].id + ' - ' + erros[key].descricao,
                quantidade = erros[key].quantidade;

            if (!resultErro[chave]) {
                resultErro[chave] = [];
            }
            resultErro[chave][serie] = quantidade;

            if (!series[serie]) {
                series[serie] = chave;
            }
        }

        for (let serie in series) {            
            let dados = [];

            for (let chave in resultErro) {
                
                if (resultErro[chave][serie]) {
                    dados.push(resultErro[chave][serie]);
                } else {
                    dados.push(0);
                }
    
                if (dataset.length == 0) {
                    dataLabels.push(chave);
                }
            }

            dataset.push({
                //stack: 'stack',
                label: serie,
                backgroundColor: corGrafico.getCor(dataset.length),
                data: dados
            });

        }

        resultData = {
            labels: dataLabels,
            datasets: dataset
        };

        return resultData;
    }

    render() {
        return (
            <Bar
                data={this.dadosErroGeral}
                height={400}
                width={800}
                options={optionsGrafico}
            />
        );
    }
}

const mapStateToProps = state => ({ listErros: state.controladorErroGeralCad.listErros })
const mapDispatchToProps = dispatch => bindActionCreators({ getErrosList }, dispatch)
export default connect(null, mapDispatchToProps)(GraficoErroGeral)