import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { init } from './detectorActions';
import LabelAndInput from '../common/form/labelAndInput';
import Button from '../common/form/button';

import If from '../common/operator/if';
import SelectCodigo from './selectDetectorCodigo';

class DetectorForm extends Component {

    render() {
        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit, readOnly } = this.props;

        return (
            <form  onSubmit={handleSubmit}>
                <div className='box-body'>

                    <Field name='controlador_id' component={LabelAndInput}
                        label='Controlador' cols='12' readOnly={true} />

                    <If condicao={!readOnly}>
                        <SelectCodigo />
                    </If>

                    <If condicao={readOnly}>
                        <Field name='id' component={LabelAndInput} type='number'
                            label='Código' cols='12' readOnly={true} />
                    </If>


                    <Field name='descricao' component={LabelAndInput}
                        label='Descrição' cols='12' placeholder='Informe a descrição' />

                </div>

                <div className='box-footer'>
                    <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}

// destroyOnUnmount: false permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um 
// form limpo para o update
DetectorForm = reduxForm({ form: 'detectorForm', destroyOnUnmount: false })(DetectorForm);

const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(null, mapDispatchToProps)(DetectorForm);