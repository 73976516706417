import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group form-group-sm'>
            <select {...props.input}
                className={`form-control ${props.className}`}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}>                

                {props.children}
            </select>
        </div>
    </Grid>
)