import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';

import { init, sendForced } from './planoForcadoAction';

import Tabs from '../common/tab/tabs';
import TabsHeader from '../common/tab/tabsHeader';
import TabsContent from '../common/tab/tabsContent';
import TabHeader from '../common/tab/tabHeader';
import TabContent from '../common/tab/tabContent';
import Form from './planoForcadoForm';

class PlanoForcado extends Component {

    componentWillMount() {
        this.props.init();
    }

    render() {

        return (
            <div>
                <ContentHeader title='Plano Forçado' small='Execução' />
                <Content>
                    <Tabs>
                        <TabsHeader>
                            <TabHeader label='Executar' icon='gavel' target='tabExec' />
                        </TabsHeader>
                        <TabsContent>
                            <TabContent id='tabExec'>                            
                                <Form onSubmit={this.props.sendForced}/>
                            </TabContent>
                        </TabsContent>
                    </Tabs>

                </Content>

            </div >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ init, sendForced }, dispatch)
export default connect(null, mapDispatchToProps)(PlanoForcado)